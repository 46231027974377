import React, { forwardRef, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import TripCheckList from '../Interface/TripCheckList';

import { SwitchTransition } from 'react-transition-group';

const TripsList = forwardRef((props, transitionRef) => {
  const { trips = [], emit = null, style, userType = 'client' } = props;
  const [view, setView] = useState('current');
  const [select, setSelect] = useState(dayjs().year());
  const [tripsToShow, setTripsToShow] = useState([]);

  useEffect(() => {
    setTripsToShow(() => {
      return trips
        ?.filter(trip => {
          const currentYear = dayjs().year();

          return view === 'current'
            ? dayjs(trip?.startDate).year() === currentYear
            : view === 'past'
            ? dayjs(trip?.startDate).year() < currentYear
            : dayjs(trip?.startDate).year() > currentYear;
        })
        ?.sort((a, b) => (a.startDate > b.startDate ? -1 : 1));
    });
  }, [trips, view]);

  useEffect(() => {
    setTripsToShow(() => {
      return trips
        ?.filter(trip => dayjs(trip?.startDate).year() === select)
        ?.sort((a, b) => (a.startDate > b.startDate ? -1 : 1));
    });
  }, [trips, select]);

  return (
    <Stack ref={transitionRef} style={style} sx={{ minWidth: 300 }}>
      {trips?.length === 0 ? (
        <Typography color="secondary">No Booked Trips</Typography>
      ) : (
        <>
          {userType === 'client' ? (
            <ToggleButtonGroup value={view} fullWidth exclusive onChange={(_, value) => setView(prev => value ?? prev)}>
              <ToggleButton value="past">Past</ToggleButton>
              <ToggleButton value="current">{dayjs().year()}</ToggleButton>
              <ToggleButton value="future">Future</ToggleButton>
            </ToggleButtonGroup>
          ) : (
            <TextField
              name="year"
              label="Year"
              value={select}
              onChange={e => setSelect(e.target.value)}
              select
              InputLabelProps={{
                htmlFor: undefined,
              }}
            >
              {trips
                .reduce((acc, current) => {
                  const year = dayjs(current?.startDate).year();

                  if (acc.includes(year)) {
                    return acc;
                  } else {
                    acc.push(year);
                    return acc;
                  }
                }, [])
                .map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
            </TextField>
          )}
          <Fade in={tripsToShow?.length === 0}>
            <Typography sx={{ textTransform: 'capitalize' }} color="secondary">
              No Trips listed for {view} Dates
            </Typography>
          </Fade>
          <SwitchTransition component={null}>
            <Fade key={`${view}${select}`}>
              <Stack>
                {tripsToShow?.map(trip => (
                  <Card key={trip?._id}>
                    <CardHeader
                      title={userType === 'client' ? trip?.type?.name : trip?.client?.user?.fullName}
                      subheader={
                        (userType === 'client' ? '' : trip?.type?.name + ', ') +
                        dayjs(trip?.startDate).format('MMM D, YYYY')
                      }
                      action={
                        <Button onClick={() => emit(trip._id)} variant="text" sx={{ ml: 4 }}>
                          View Details
                        </Button>
                      }
                    />
                    {userType === 'guide' ? null : (
                      <CardContent>
                        <TripCheckList userType={userType} trip={trip} />
                      </CardContent>
                    )}
                  </Card>
                ))}
              </Stack>
            </Fade>
          </SwitchTransition>
        </>
      )}
    </Stack>
  );
});

export default TripsList;
