import React from 'react';
import dayjs from 'dayjs';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Check from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

const TripCheckList = props => {
  const { userType, trip } = props;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Stack direction="row">
          <Typography>Contract Signed</Typography>
          {trip?.signed ? <Check color="success" /> : <Cancel color="error" />}
        </Stack>
        <Stack direction="row">
          <Typography>Deposit Made</Typography>
          {trip?.amountPaid > 0 ? <Check color="success" /> : <Cancel color="error" />}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Stack direction="row">
          <Typography>Travel Plans</Typography>
          {(trip?.travel?.offered && trip?.travel?.accepted === false) ||
          trip?.travel?.accepted ||
          !trip?.travel?.offered ? (
            <Check color="success" />
          ) : (
            <Cancel color="error" />
          )}
        </Stack>
        <Stack direction="row">
          <Typography>Full Payment</Typography>
          {trip?.paid ? <Check color="success" /> : <Cancel color="error" />}
        </Stack>
      </Stack>
      {userType === 'admin' && trip?.reminderSent ? (
        <Stack direction="row" justifyContent="start" sx={{ pt: 2 }}>
          <Typography color="secondary">Reminder sent:</Typography>
          <Typography>{dayjs(trip?.reminderSent).format('MMM D, YYYY')}</Typography>
        </Stack>
      ) : null}
    </>
  );
};

export default TripCheckList;
