import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  message: null,
  error: null,
  profile: {
    name: {
      first: null,
      last: null,
    },
    birthday: null,
    height: null,
    weight: null,
    address: {},
    phone: null,
    secondaryPhone: null,
    email: null,
    emergencyContact: {
      name: null,
      phone: null,
    },
    dietaryRestrictions: null,
    physicalCondition: null,
    medical: {
      conditions: null,
      allergies: null,
    },
    WIN: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLIENT.INIT:
      return { ...state, loading: true, error: null };
    case actionTypes.CLIENT.GET_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: { ...state.profile, ...action.data } };
    case actionTypes.CLIENT.EDIT_PROFILE_SUCCESS:
      return { ...state, loading: false, message: 'Profile Updated!', profile: { ...state.profile, ...action.data } };
    case actionTypes.CLIENT.REMOVE_PROFILE_SUCCESS:
      return initialState;
    case actionTypes.CLIENT.ERROR:
      return { ...state, loading: false, error: action.error };
    case actionTypes.CLIENT.CLEAR_ERR:
      return { ...state, error: null };
    case actionTypes.CLIENT.CLEAR_MSG:
      return { ...state, message: null };
    default:
      return state;
  }
};

export default reducer;
