import * as actions from '../actions';
import { put } from 'redux-saga/effects';
import server from '../../assets/axios';

export function* tripAdminFetchSaga() {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.get('/trip');

    if (status === 200) {
      yield put(actions.tripFetchSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripGuideFetchSaga() {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.get('/trip/guide');

    if (status === 200) {
      yield put(actions.tripFetchSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripClientFetchSaga() {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.get('/trip/client');

    if (status === 200) {
      yield put(actions.tripFetchSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripCreateSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.post('/trip', action.data);

    if (status === 200) {
      yield put(actions.tripCreateSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripEditSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.put('/trip', action.data);

    if (status === 200) {
      yield put(actions.tripEditSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripRemoveSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.delete('/trip', { data: action.data });

    if (status === 200) {
      yield put(actions.tripRemoveSuccess(action.data.trip));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripResendSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.get('/trip/resend', { params: { trip: action.data } });

    if (status === 200) {
      yield put(actions.tripResendSuccess());
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripAddPaymentSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.post('/trip/payment', action.data);

    if (status === 200) {
      yield put(actions.tripPaymentSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripEditPaymentSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.put('/trip/payment', action.data);

    if (status === 200) {
      yield put(actions.tripPaymentSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripRemovePaymentSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.delete('/trip/payment', { data: action.data });

    if (status === 200) {
      yield put(actions.tripPaymentSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripTravelSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.put('/trip/travel', action.data);

    if (status === 200) {
      yield put(actions.tripTravelSuccess(action.data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripSignContractSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.put('/trip/sign', action.data);

    if (status === 200) {
      yield put(actions.signContractSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}

export function* tripDownloadContractSaga(action) {
  yield put(actions.tripInit());
  try {
    const { status, data } = yield server.get('/trip/download', {
      params: { contract: action.data },
      responseType: 'arraybuffer',
    });

    if (status === 200) {
      yield put(actions.downloadContractSuccess(data));
    } else {
      yield put(actions.tripError(data.error));
    }
  } catch (err) {
    yield put(actions.tripError(err.message));
  }
}
