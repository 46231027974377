import React, { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { appUpdate } from '../../store/actions';

import { SwitchTransition } from 'react-transition-group';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ExampleContract from './ExampleContract';

import AddressForm from '../../components/Interface/AddressForm';

import { appInfoSchema } from '../../assets/validationSchemas';

const OutfitterInformation = forwardRef(({ style }, transitionRef) => {
  const { loading, name, phone, email, bio, website, address, sendAlertsAt } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: name ?? '',
      website: website ?? '',
      phone: phone ?? '',
      email: email ?? '',
      street: address?.street ?? '',
      suite: address?.suite ?? '',
      city: address?.city ?? '',
      postal: address?.postal ?? '',
      state: address?.state ?? '',
      country: address?.country ?? '',
      bio: bio ?? '',
      sendAlertsAt: sendAlertsAt ?? 60,
    },
    enableReinitialize: true,
    validationSchema: appInfoSchema,
    onSubmit: async values => {
      const { street, suite, city, postal, state, country, ...rest } = values;
      dispatch(appUpdate({ ...rest, address: { street, suite, city, postal, state, country } }));
    },
  });

  const createInput = (formikKey, label, multiline = false) => (
    <TextField
      key={formikKey}
      id={formikKey}
      name={formikKey}
      label={label}
      multiline={multiline}
      autoComplete="off"
      value={formik.values[formikKey]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[formikKey] && Boolean(formik.errors[formikKey])}
      helperText={formik.touched[formikKey] && formik.errors[formikKey]}
    />
  );

  return (
    <Stack spacing={2} ref={transitionRef} style={style}>
      <Typography variant="h6" color="secondary" sx={{ maxWidth: '75ch', my: 2 }} align="center">
        Please ensure all information on this page is correct. Your clients will be able to see this information and use
        it for billing or contact purposes.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={2} alignItems="start">
          <Grid item xs={12} md={6} xl={4}>
            <Stack>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ pr: 1, pb: 4 }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Outfit Information</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="secondary">
                    This is your business information, personal can be found under Profile from the top menu.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createInput('name', 'Outfit Name')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createInput('email', 'Outfit eMail')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createInput('phone', 'Phone')}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createInput('website', 'Website')}
                </Grid>
                <Grid item xs={12}>
                  {createInput('bio', 'About Your Outfit', true)}
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Stack>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ pr: 1, pb: 4 }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Mailing Address For Payment</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="secondary">Used for billing purposes, enter your business address</Typography>
                </Grid>
                <AddressForm formik={formik} />
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} xl={4}>
            <Stack>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ pr: 1, pb: 4, maxWidth: 750, minWidth: 300 }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Client Reminder</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="secondary">Choose when your clients will receive their trip reminder.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack>
                    <TextField
                      id="sendAlertsAt"
                      name="sendAlertsAt"
                      value={formik.values.sendAlertsAt}
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.sendAlertsAt && Boolean(formik.errors.sendAlertsAt)}
                      helperText={formik.touched.sendAlertsAt && formik.errors.sendAlertsAt}
                      inputProps={{ style: { textAlign: 'center' } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ pl: 2 }}>
                            Alert
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ pr: 2 }}>
                            Days Before Trip
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack mt={4}>
                    <ExampleContract />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <Collapse in={formik.dirty}>
          <Stack sx={{ m: 4 }}>
            <SwitchTransition>
              <Fade key={loading ? 'progress' : 'buttons'}>
                {loading ? (
                  <CircularProgress sx={{ mt: 2 }} />
                ) : (
                  <Stack direction="row" sx={{ mt: 2 }}>
                    <Button onClick={formik.handleReset} disabled={!formik.dirty}>
                      Undo
                    </Button>
                    <Button onClick={formik.handleSubmit} disabled={!formik.dirty} color="tertiary">
                      Update
                    </Button>
                  </Stack>
                )}
              </Fade>
            </SwitchTransition>
          </Stack>
        </Collapse>
      </form>
    </Stack>
  );
});

export default OutfitterInformation;
