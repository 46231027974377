import * as actionTypes from './actionTypes';

export const guideInit = () => ({ type: actionTypes.GUIDE.INIT });
export const guideError = err => ({ type: actionTypes.GUIDE.ERROR, error: err });

export const guideGetProfile = () => ({ type: actionTypes.GUIDE.GET_PROFILE });
export const guideGetProfileSuccess = data => ({ type: actionTypes.GUIDE.GET_PROFILE_SUCCESS, data });

export const guideEditProfile = data => ({ type: actionTypes.GUIDE.EDIT_PROFILE, data });
export const guideEditProfileSuccess = data => ({ type: actionTypes.GUIDE.EDIT_PROFILE_SUCCESS, data });

export const guideClearErr = () => ({ type: actionTypes.GUIDE.CLEAR_ERR });
export const guideClearMsg = () => ({ type: actionTypes.GUIDE.CLEAR_MSG });
