import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AppInfo from './OutfitterInformation';
import Logo from '../Photos/Logo/Display';
import KeyValue from '../../components/Interface/KeyValue';

import ListPreview from '../../components/Interface/ListPreview';
import Expense from '../../components/Expense/Expense';
import Post from '../../components/Post';
import Referral from '../../components/Referral';

const Dashboard = () => {
  const { admins, guides, clients } = useSelector(state => state.admin);
  const { active, posts, referrals, expenses } = useSelector(state => state.app);
  const trips = useSelector(state => state.trip.trips);

  const route = useNavigate();

  return (
    <Stack spacing={2} pt={2}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4} justifyContent="space-between">
        <Logo />
        <Typography variant="h3">Dashboard</Typography>
        <Stack alignItems="end" direction={{ xs: 'row', sm: 'column' }}>
          <Button onClick={() => route('/subscription')}>Subscription</Button>
          <Button onClick={() => route('/policies')} color="tertiary">
            Trip Policies
          </Button>
        </Stack>
      </Stack>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }} alignItems="start" justifyContent="center">
        <Grid item xs={12}>
          <Stack>
            <Grid container spacing={2} sx={{ pr: 2 }}>
              <Grid item xs={4} xl={2}>
                <KeyValue title="Subscription" value={active ? 'Active' : 'Inactive'} />
              </Grid>
              <Grid item xs={4} xl={2}>
                <KeyValue title="Booked Trips" value={trips?.length ?? 0} />
              </Grid>
              <Grid item xs={4} xl={2}>
                <KeyValue
                  title="Booked Value"
                  value={`$${
                    trips
                      ?.reduce((acc, current) => parseFloat(acc) + parseFloat(current.totalValue), 0)
                      .toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0
                  }`}
                />
              </Grid>
              <Grid item xs={4} xl={2}>
                <KeyValue title="Admins" value={admins?.length ?? 0} />
              </Grid>
              <Grid item xs={4} xl={2}>
                <KeyValue title="Guides" value={guides?.length ?? 0} />
              </Grid>
              <Grid item xs={4} xl={2}>
                <KeyValue title="Clients" value={clients?.length ?? 0} />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item>
          <AppInfo />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <ListPreview title="Expenses" list={expenses} Component={Expense} routeToAll={() => route('/expenses')} />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <ListPreview title="Posts" list={posts} Component={Post} routeToAll={() => route('/posts')} />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <ListPreview
            title="Referrals"
            list={referrals}
            Component={Referral}
            routeToAll={() => route('/referrals')}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Dashboard;
