import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const usePushNotifications = () => {
  const [needPushEnabled, setNeedPushEnabled] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const route = useNavigate();

  const [_, setSent] = useState(false); // eslint-disable-line no-unused-vars
  useEffect(() => {
    const listener = event => {
      if (event.origin !== window.location.origin) return;

      setNeedPushEnabled(prev => event?.data?.needPushEnable ?? prev);

      if (event.data?.type === 'push') {
        const { action, path } = event.data;

        if (action === 'route') route(path);
      }
    };

    navigator.serviceWorker.addEventListener('message', listener);
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
      navigator.serviceWorker.removeEventListener('message', listener);
    };
  }, [route]);

  useEffect(() => {
    setSent(async prev => {
      if (!prev && isAuthenticated) {
        const token = await getAccessTokenSilently();
        window.postMessage({ checkPushStatus: true, token }, window.location.origin);
        return true;
      } else {
        return prev;
      }
    });

    return () => setSent(prev => (prev === true ? true : false));
  }, [isAuthenticated, getAccessTokenSilently]);

  return needPushEnabled;
};

export default usePushNotifications;
