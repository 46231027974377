import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { legacy_createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './store/reducers';
import sagaRoot from './store/sagas';
import './index.css';
import ThemeProvider from './theme/Provider';
import { LicenseInfo } from '@mui/x-license-pro';

import reportWebVitals from './reportWebVitals';
import { register } from './serviceWorker';

import App from './App';

const composeEnhancers =
  process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const sagaMiddleware = createSagaMiddleware();

const store = legacy_createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, sagaMiddleware)));

sagaMiddleware.run(sagaRoot);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO);

const app = (
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        redirect_uri: process.env.REACT_APP_LOGIN_REDIRECT,
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <App/>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

const root = createRoot(document.getElementById('root'));

root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
register();
