import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SwitchTransition } from 'react-transition-group';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import CreateCustomEvent from '../CustomEvents/Create';
import EditCustomEvent from '../CustomEvents/Edit';

import Calendar from '../../components/Calendar/Calendar';
import List from '../../components/Trip/TripList';

const TripList = () => {
  const { name, events, userType } = useSelector(state => state.app);
  const { loading, trips } = useSelector(state => state.trip);
  const types = useSelector(state => state.admin.types);
  const {
    user: { sub },
  } = useAuth0();
  const route = useNavigate();

  const [view, setView] = useState(userType === 'client' ? 'list' : 'calendar');
  const [event, setEvent] = useState(null);

  const selectTrip = tripId => {
    if (userType === 'client') {
      route(`/overview/${tripId}`);
    } else {
      route(`/trips/${tripId}`);
    }
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h4">{userType === 'admin' ? 'Trips' : 'Your Trips'}</Typography>
      {userType === 'admin' ? (
        types?.length === 0 ? (
          <Button onClick={() => route('/trips/types/create')}>Create Trip Type</Button>
        ) : (
          <Stack direction="row" spacing={4}>
            <Button onClick={() => route('/trips/create')}>Book A Trip</Button>
            <Button onClick={() => route('/trips/types')}>Trip Types</Button>
            <CreateCustomEvent />
          </Stack>
        )
      ) : (
        <CreateCustomEvent />
      )}
      <Collapse in={loading} unmountOnExit>
        <CircularProgress />
      </Collapse>
      <ToggleButtonGroup value={view} fullWidth exclusive onChange={(_, value) => setView(prev => value ?? prev)}>
        <ToggleButton value="calendar">Calendar</ToggleButton>
        <ToggleButton value="list">List</ToggleButton>
      </ToggleButtonGroup>
      <SwitchTransition>
        <Collapse key={view}>
          {view === 'calendar' ? (
            <Calendar
              name={name}
              trips={trips}
              events={events}
              tripClick={selectTrip}
              eventClick={data => setEvent(data)}
              userType={userType}
              sub={sub}
            />
          ) : (
            <List
              trips={userType === 'guide' ? trips.filter(trip => trip?.guide?.auth0 === sub) : trips}
              emit={selectTrip}
              userType={userType}
            />
          )}
        </Collapse>
      </SwitchTransition>
      <EditCustomEvent eventId={event} close={() => setEvent(null)} />
    </Stack>
  );
};

export default TripList;
