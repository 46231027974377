import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import ArrowBack from '@mui/icons-material/ArrowBack';

const BackNavigation = ({ text, url }) => {
  const route = useNavigate();
  const { search } = useLocation();

  // Uses query pattern ?redirect=[url to return to]&text=[text visible next to the back arrow]
  // This overrides the default values provided by props text and url
  const [searchUrl, searchText] = search.split('&');

  const checkUrl = () => {
    // ensure it's a relative path
    if (searchUrl && searchUrl?.split('=')?.[1]?.[0] === '/') {
      route(searchUrl.split('=')[1]);
    } else {
      route(url);
    }
  }

  return (
    <Stack alignItems="start" sx={{ width: '95vw', mb: 1, ml: 1 }}>
      <Button
        onClick={checkUrl}
        startIcon={<ArrowBack />}
        variant="text"
        sx={{ ml: '5vw', p: 0 }}
      >
        {searchText ? decodeURI(searchText.split('=')[1]) : text}
      </Button>
    </Stack>
  );
};

export default BackNavigation;
