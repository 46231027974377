import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  message: null,
  appInitiated: false,
  active: false,
  onboard: false,
  logo: null,
  logoCache: 0,
  bio: 'Brought To You By Easy Outfitting',
  name: 'Easy Outfitting',
  phone: '',
  email: '',
  website: '',
  address: {
    street: '',
    suite: '',
    city: '',
    postal: '',
    state: '',
    country: '',
  },
  checkoutUrl: null,
  contactList: [],
  referrals: [],
  posts: [],
  photos: [],
  expenses: [],
  events: [],
  userType: 'client',
  passwordChangeEmailSent: false,
  sendAlertsAt: 60,
  defaultPolicies: {
    cancellation: '',
    payment: '',
    wound: '',
  },
  policies: {
    cancellation: 'default',
    payment: 'default',
    wound: 'default',
  },
};

const sortDescending = (array, field) => array.sort((a, b) => (a[field] > b[field] ? -1 : 1));

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP.INIT:
      return { ...state, loading: true, error: null };
    case actionTypes.APP.ERROR:
      return { ...state, loading: false, error: action.error };
    case actionTypes.APP.CLEAR_ERR:
      return { ...state, error: null };
    case actionTypes.APP.CLEAR_MSG:
      return { ...state, message: null };
    case actionTypes.APP.INITIALIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        appInitiated: true,
        active: action.data.active ?? false,
        address: action.data?.address ?? initialState.address,
        bio: action.data.bio ?? initialState.bio,
        contactList: action.data.contactList ?? initialState.contactList,
        email: action.data?.email ?? initialState.email,
        events: action.data?.events ?? initialState.events,
        expenses: sortDescending(action.data.expenses ?? [], 'date'),
        logo: action.data.logo ?? null,
        name: action.data?.name ?? initialState.name,
        onboard: action.data?.onboard ?? initialState.onboard,
        phone: action.data?.phone ?? initialState.phone,
        photos: action.data.photos ?? [],
        posts: sortDescending(action.data.posts ?? [], 'posted'),
        referrals: action.data.referrals ?? initialState.referrals,
        userType: action.data.userType,
        website: action.data?.website ?? initialState.website,
        sendAlertsAt: action?.data?.sendAlertsAt ?? initialState.sendAlertsAt,
        defaultPolicies: action.data.defaultPolicies ?? initialState.defaultPolicies,
        policies: action.data.policies ?? initialState?.policies,
      };
    case actionTypes.APP.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Your Outfitter Information Has Been Updated!',
        bio: action.data?.bio ?? state.bio,
        name: action.data?.name ?? state.name,
        phone: action.data?.phone ?? state.phone,
        email: action.data?.email ?? state.email,
        website: action.data?.website ?? state.website,
        address: action.data?.address ?? state.address,
        sendAlertsAt: action.data?.sendAlertsAt ?? state.sendAlertsAt,
      };
    case actionTypes.APP.PREPARE_CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkoutUrl: action.data,
      };
    case actionTypes.APP.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        active: false,
      };
    case actionTypes.APP.COMPLETE_ONBOARDING_SUCCESS:
      return { ...state, loading: false, message: 'Onboarding Complete!', onboard: false };
    case actionTypes.APP.GET_CONTACTS_SUCCESS:
      return { ...state, loading: false, contactList: action.data };
    case actionTypes.APP.NEW_CONTACT_SUCCESS:
      return { ...state, loading: false, message: 'Contact Added!', contactList: action.data };
    case actionTypes.APP.EDIT_CONTACT_SUCCESS:
      return { ...state, loading: false, message: 'Contact Updated!', contactList: action.data };
    case actionTypes.APP.REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Contact Removed!',
        contactList: state.contactList.filter(contact => contact._id !== action.data),
      };
    case actionTypes.APP.CREATE_ALBUM_SUCCESS:
      return { ...state, loading: false, message: 'Album Created!', photos: [...state.photos, action.data] };
    case actionTypes.APP.EDIT_ALBUM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Ablum Renamed!',
        photos: state.photos.map(photo =>
          photo._id === action.data.album ? { ...photo, title: action.data.title } : photo
        ),
      };
    case actionTypes.APP.REMOVE_ALBUM_SUCCESS:
      return { ...state, loading: false, photos: state.photos.filter(photo => photo._id !== action.data) };
    case actionTypes.APP.UPLOAD_PHOTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Photo's Uploaded!",
        photos: state.photos.map(album => (album._id === action.data._id ? action.data : album)),
      };
    case actionTypes.APP.EDIT_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Caption Updated!',
        photos: state.photos.map(album =>
          album._id === action.data.album
            ? {
                ...album,
                photos: album.photos.map(photo =>
                  photo._id === action.data._id ? { ...photo, caption: action.data.caption } : photo
                ),
              }
            : album
        ),
      };
    case actionTypes.APP.REMOVE_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Photo Removed!',
        photos: state.photos.map(album =>
          album._id === action.data.album
            ? { ...album, photos: album.photos.filter(photo => photo._id !== action.data._id) }
            : album
        ),
      };
    case actionTypes.APP.GET_EXPENSES_SUCCESS:
      return { ...state, loading: false, expenses: sortDescending(action.data, 'date') };
    case actionTypes.APP.SUBMIT_EXPENSE_SUCCESS:
      return { ...state, loading: false, message: 'Expense Submitted!', expenses: [action.data, ...state.expenses] };
    case actionTypes.APP.EDIT_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Expense Updated!',
        expenses: state.expenses.map(exp => (exp._id === action.data._id ? action.data : exp)),
      };
    case actionTypes.APP.REMOVE_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Expense Removed!',
        expenses: state.expenses.filter(exp => exp._id !== action.data),
      };
    case actionTypes.APP.REIMBURSE_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Expense Reimbursement Changed!',
        expenses: state.expenses.map(exp =>
          exp._id === action.data.expense ? { ...exp, reimbursed: action.data.paid } : exp
        ),
      };
    case actionTypes.APP.CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, message: 'Check Your Email!', passwordChangeEmailSent: true };
    case actionTypes.ADMIN.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        logo: action.data,
        logoCache: ++state.logoCache,
      };
    case actionTypes.APP.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Your Event Has Been Added!',
        events: [...state.events, action.data],
      };
    case actionTypes.APP.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Event Updated!',
        events: state.events.map(event =>
          event._id === action.data.event ? { ...action.data, _id: event._id } : event
        ),
      };
    case actionTypes.APP.REMOVE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Event Removed!',
        events: state.events.filter(event => event._id !== action.data),
      };
    case actionTypes.ADMIN.GET_REFERRALS_SUCCESS:
      return { ...state, loading: false, referrals: action.data };
    case actionTypes.ADMIN.ADD_REFERRAL_SUCCESS:
      return { ...state, loading: false, referrals: action.data };
    case actionTypes.ADMIN.EDIT_REFERRAL_SUCCESS:
      return {
        ...state,
        loading: false,
        referrals: state.referrals.map(referral => (referral._id === action.data._id ? action.data : referral)),
      };
    case actionTypes.ADMIN.REMOVE_REFERRAL_SUCCESS:
      return {
        ...state,
        loading: false,
        referrals: state.referrals.filter(referral => referral._id !== action.data),
      };
    case actionTypes.ADMIN.GET_POSTS_SUCCESS:
      return { ...state, loading: false, posts: sortDescending(action.data, 'posted') };
    case actionTypes.ADMIN.CREATE_POST_SUCCESS:
      return { ...state, loading: false, posts: sortDescending(action.data, 'posted') };
    case actionTypes.ADMIN.EDIT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: state.posts.map(post => (post._id === action.data._id ? action.data : post)),
      };
    case actionTypes.ADMIN.REMOVE_POST_SUCCESS:
      return { ...state, loading: false, posts: state.posts.filter(post => post._id !== action.data) };
    case actionTypes.ADMIN.UPDATE_POLICIES_SUCCESS:
      return { ...state, loading: false, message: 'Policies Updated!', policies: action.data };
    case actionTypes.TRIP.REMOVE_SUCCESS:
      return { ...state, expenses: state.expenses.filter(exp => exp.trip._id !== action.data) };
    default:
      return state;
  }
};

export default reducer;
