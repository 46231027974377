import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminGetExampleContract } from '../../store/actions';

import { SwitchTransition } from 'react-transition-group';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Close from '@mui/icons-material/Close';

const ExampleContract = () => {
  const { loading, exampleContract } = useSelector(state => state.admin);
  const appName = useSelector(state => state.app.name);
  const dispatch = useDispatch();

  const [open, toggleOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (open) {
      dispatch(adminGetExampleContract());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (!!exampleContract) {
      const file = new Blob([exampleContract], { type: 'application/pdf' });
      const url = URL.createObjectURL(file);

      setFile(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [exampleContract]);

  return (
    <>
      <Modal open={open} onClose={() => toggleOpen(false)}>
        <Slide in={open}>
          <Card>
            <CardHeader
              title="Download Example Contract"
              action={
                <IconButton onClick={() => toggleOpen(false)}>
                  <Close color="error" />
                </IconButton>
              }
            />
            <CardContent>
              <SwitchTransition>
                <Collapse key={!exampleContract || loading ? 'loading' : 'button'}>
                  {!exampleContract || loading ? (
                    <Stack>
                      <CircularProgress />
                      <Typography>Please wait while we prepare your download</Typography>
                    </Stack>
                  ) : (
                    <Stack>
                      <Button color="tertiary" href={file} download={`${appName} Example Contract.pdf`} disabled={!file}>
                        Download
                      </Button>
                    </Stack>
                  )}
                </Collapse>
              </SwitchTransition>
            </CardContent>
          </Card>
        </Slide>
      </Modal>
      <Button onClick={() => toggleOpen(true)}>
        Download A Blank Example Contract
      </Button>
    </>
  );
};

export default ExampleContract;
