import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Collapse from '@mui/material/Collapse';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Adjust from '@mui/icons-material/Adjust';
import Close from '@mui/icons-material/Close';
import Comment from '@mui/icons-material/Comment';
import Home from '@mui/icons-material/Home';
import Info from '@mui/icons-material/Info';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/icons-material/Menu';
import Person from '@mui/icons-material/Person';
import Store from '@mui/icons-material/Store';

import BackNav from './Interface/BackNavigation';

const Header = props => {
  const { auth, userType } = props;

  const [open, toggle] = useState(false);
  const route = useNavigate();
  const url = useLocation();

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  let routes = [
    { name: 'Home', path: '/' },
    { name: 'Login', path: '/login' },
    { name: 'About', path: '/about' },
  ];

  if (auth && userType === 'admin') {
    routes = [
      { name: 'Profile', path: '/profile' },
      { name: 'Posts', path: '/posts' },
      { name: 'Referrals', path: '/referrals' },
      { name: 'About', path: '/about' },
      { name: 'Logout', path: '/logout' },
    ];
  } else if (auth && userType === 'guide') {
    routes = [
      { name: 'Profile', path: '/profile' },
      { name: 'Posts', path: '/posts' },
      { name: 'Referrals', path: '/referrals' },
      { name: 'About', path: '/about' },
      { name: 'Logout', path: '/logout' },
    ];
  } else if (auth && userType === 'client') {
    routes = [
      { name: 'Profile', path: '/profile' },
      { name: 'About', path: '/about' },
      { name: 'Logout', path: '/logout' },
    ];
  }

  const placeIcon = icon => {
    switch (icon) {
      case 'About':
        return <Info color="primary" />;
      case 'Home':
        return <Home color="primary" />;
      case 'Trips':
        return <Adjust color="primary" />;
      case 'Login':
        return <Login color="primary" />;
      case 'Logout':
        return <Logout color="primary" />;
      case 'Posts':
        return <Comment color="primary" />;
      case 'Profile':
        return <Person color="primary" />;
      case 'Referrals':
        return <Store color="primary" />;
      default:
        return null;
    }
  };

  const routeClicked = path => {
    route(path);
    toggle(false);
  };

  return (
    <AppBar position="fixed" color="header">
      <Toolbar>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack spacing={0} alignItems="start">
            <Typography variant="h4" component="h1" color="primary" onClick={() => route('/')}>
              {process.env.REACT_APP_INSTANCE_NAME}
            </Typography>
            <Typography variant="caption" component="h1" color="primary" onClick={() => route('/')}>
              powered by Easy Outfitting
            </Typography>
          </Stack>
          <Stack direction="row">
            <IconButton onClick={() => toggle(!open)}>
              <Menu color="primary" fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
      <Drawer anchor="right" open={open} onClose={() => toggle(false)}>
        <Stack mt={1.5} alignItems="end">
          <IconButton size="large" onClick={() => toggle(false)} sx={{ mr: 2 }}>
            <Close color="secondary" fontSize="large" />
          </IconButton>
          <List>
            {routes.map(route => {
              return (
                <ListItemButton
                  key={route.name}
                  onClick={() => routeClicked(route.path)}
                  selected={url.pathname === route.path}
                  sx={{ px: 6 }}
                >
                  <ListItemIcon>{placeIcon(route.name)}</ListItemIcon>
                  <ListItemText primary={route.name} />
                </ListItemButton>
              );
            })}
          </List>
        </Stack>
        <Stack sx={{ height: '100%' }}>
          <img src="/eo_192.png" alt="easy outfitting logo" />
        </Stack>
        <Stack direction="row" sx={{ position: 'absolute', bottom: 5, right: 10 }}>
          <Typography variant="caption">Version</Typography>
          <Typography variant="caption" color="primary">
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Stack>
      </Drawer>
      <Collapse in={url.pathname.includes('/overview/') && userType === 'client'}>
        <Toolbar variant="dense" disableGutters sx={{ minHeight: 36 }}>
          <BackNav text="Back to All Trips" url="/trips" />
        </Toolbar>
      </Collapse>
    </AppBar>
  );
};

export default Header;
