import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en-ca';

import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker as DateSelector } from '@mui/x-date-pickers-pro/DateRangePicker';

const DateRange = props => {
  const { disableFuture, disablePast, minimum, maximum, emit, start, end, openTo, error, event } = props;

  const changeHandler = data => {
    emit({
      start: data[0]?.startOf('day')?.hour(12).$d,
      end: data[1]?.startOf('day')?.hour(12).$d,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-ca">
      <DateSelector
        disableFuture={disableFuture ?? false}
        disablePast={disablePast ?? false}
        minDate={minimum && dayjs(minimum)}
        maxDate={maximum && dayjs(maximum)}
        openTo={openTo ?? 'day'}
        localeText={{
          start: event ? 'Start Date' : 'Trip Start Date',
          end: event ? 'End Date' : 'Trip End Date',
        }}
        onAccept={changeHandler}
        onChange={changeHandler}
        format="MMM DD, YYYY"
        value={[start && dayjs(start), end && dayjs(end)]}
        slots={{
          fieldSeparator: () => null,
        }}
      />
      <Collapse in={!!error}>
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      </Collapse>
    </LocalizationProvider>
  );
};

export default DateRange;
