import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { appCreateEvent } from '../../store/actions';

import { SwitchTransition } from 'react-transition-group';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Close from '@mui/icons-material/Close';

import DateRange from '../../components/Interface/DateRange';

import { eventSchema } from '../../assets/validationSchemas';

const CreateCustomEvent = () => {
  const { loading, events } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const [open, toggleModal] = useState(false);

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      title: '',
      body: '',
    },
    validationSchema: eventSchema,
    onSubmit: async values => {
      dispatch(appCreateEvent(values));
    },
  });

  useEffect(() => {
    resetForm();
  }, [resetForm, events]);

  const createInput = (formikKey, label, multiline = false) => (
    <TextField
      key={formikKey}
      id={formikKey}
      name={formikKey}
      label={label}
      value={formik.values[formikKey]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      multiline={multiline}
      error={formik.touched[formikKey] && Boolean(formik.errors[formikKey])}
      helperText={formik.touched[formikKey] && formik.errors[formikKey]}
    />
  );

  return (
    <>
      <Button onClick={() => toggleModal(true)}>Add Event</Button>
      <Modal open={open ?? false} onClose={() => toggleModal(false)}>
        <Slide in={open ?? false}>
          <Card>
            <CardHeader
              title="Add Event"
              subheader="These events are only visible to you"
              action={
                <IconButton onClick={() => toggleModal(false)}>
                  <Close color="error" />
                </IconButton>
              }
            />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12}>
                    <DateRange
                      disablePast
                      event
                      emit={data => {
                        formik.setFieldTouched('startDate');
                        formik.setFieldTouched('endDate');
                        formik.setFieldValue('startDate', data?.start ?? null, true);
                        formik.setFieldValue('endDate', data?.end ?? null, true);
                      }}
                      start={formik.values.startDate}
                      end={formik.values.endDate}
                      error={
                        (formik.touched.startDate && formik.errors.startDate) ||
                        (formik.touched.endDate && formik.errors.endDate)
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <DatePicker
                      disablePast
                      emit={data => {
                        formik.setFieldTouched('startDate');
                        formik.setFieldValue('startDate', data ?? null, true);
                      }}
                      label="Start Date"
                      value={formik.values.startDate}
                      error={formik.touched.startDate && formik.errors.startDate}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      disablePast
                      minimum={formik.values.startDate}
                      emit={data => {
                        formik.setFieldTouched('endDate');
                        formik.setFieldValue('endDate', data ?? null, true);
                      }}
                      label="End Date"
                      value={formik.values.endDate}
                      error={formik.touched.endDate && formik.errors.endDate}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    {createInput('title', 'Title', false)}
                  </Grid>
                  <Grid item xs={12}>
                    {createInput('body', 'Description', true)}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack pt={2}>
                      <SwitchTransition>
                        <Fade key={loading ? 'loader' : 'button'}>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <Button onClick={formik.handleSubmit} disabled={!formik.dirty}>
                              Create
                            </Button>
                          )}
                        </Fade>
                      </SwitchTransition>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Slide>
      </Modal>
    </>
  );
};

export default CreateCustomEvent;
