import * as actionTypes from '../actions/actionTypes';
import { takeLatest } from 'redux-saga/effects';

import {
  adminGetProfileSaga,
  adminEditProfileSaga,
  adminUploadLogoSaga,
  adminGetAllUsersSaga,
  adminGetUserSaga,
  adminCreateUserSaga,
  adminEditUserSaga,
  adminRemoveUserSaga,
  adminSearchUsersSaga,
  adminClientWinSaga,
  adminGetTripTypeSaga,
  adminCreateTripTypeSaga,
  adminEditTripTypeSaga,
  adminRemoveTripTypeSaga,
  adminGetReferralsSaga,
  adminCreateReferralSaga,
  adminEditReferralSaga,
  adminRemoveReferralSaga,
  adminGetPostsSaga,
  adminCreatePostSaga,
  adminEditPostSaga,
  adminRemovePostSaga,
  adminUpdatePoliciesSaga,
  adminGetExampleContractSaga,
  adminGetInvoicesSaga,
} from './admin.saga';

import {
  appInitSaga,
  appUpdateSaga,
  appCheckoutSaga,
  appCancelSaga,
  appCompleteOnboardingSaga,
  appGetContactsSaga,
  appNewContactSaga,
  appEditContactSaga,
  appRemoveContactSaga,
  appNewAlbumSaga,
  appEditAlbumSaga,
  appRemoveAlbumSaga,
  appUploadPhotosSaga,
  appEditPhotoSaga,
  appRemovePhotoSaga,
  appGetExpensesSaga,
  appSubmitExpenseSaga,
  appEditExpenseSaga,
  appRemoveExpenseSaga,
  appReimburseExpenseSaga,
  appChangePasswordSaga,
  appCreateEventSaga,
  appEditEventSaga,
  appRemoveEventSaga,
} from './app.saga';

import { clientGetProfileSaga, clientEditProfileSaga, clientRemoveProfileSaga } from './client.saga';

import { guideGetProfileSaga, guideEditProfileSaga } from './guide.saga';

import {
  tripAdminFetchSaga,
  tripGuideFetchSaga,
  tripClientFetchSaga,
  tripCreateSaga,
  tripEditSaga,
  tripRemoveSaga,
  tripResendSaga,
  tripAddPaymentSaga,
  tripEditPaymentSaga,
  tripRemovePaymentSaga,
  tripTravelSaga,
  tripSignContractSaga,
  tripDownloadContractSaga,
} from './trip.saga';

export default function* rootSaga() {
  // ADMIN
  yield takeLatest(actionTypes.ADMIN.GET_PROFILE, adminGetProfileSaga);
  yield takeLatest(actionTypes.ADMIN.EDIT_PROFILE, adminEditProfileSaga);
  yield takeLatest(actionTypes.ADMIN.UPLOAD_LOGO, adminUploadLogoSaga);
  yield takeLatest(actionTypes.ADMIN.GET_ALL_USERS, adminGetAllUsersSaga);
  yield takeLatest(actionTypes.ADMIN.GET_USER, adminGetUserSaga);
  yield takeLatest(actionTypes.ADMIN.CREATE_USER, adminCreateUserSaga);
  yield takeLatest(actionTypes.ADMIN.EDIT_USER, adminEditUserSaga);
  yield takeLatest(actionTypes.ADMIN.REMOVE_USER, adminRemoveUserSaga);
  yield takeLatest(actionTypes.ADMIN.SEARCH_USERS, adminSearchUsersSaga);
  yield takeLatest(actionTypes.ADMIN.CLIENT_WIN, adminClientWinSaga);
  yield takeLatest(actionTypes.ADMIN.GET_TRIP_TYPE, adminGetTripTypeSaga);
  yield takeLatest(actionTypes.ADMIN.CREATE_TRIP_TYPE, adminCreateTripTypeSaga);
  yield takeLatest(actionTypes.ADMIN.EDIT_TRIP_TYPE, adminEditTripTypeSaga);
  yield takeLatest(actionTypes.ADMIN.REMOVE_TRIP_TYPE, adminRemoveTripTypeSaga);
  yield takeLatest(actionTypes.ADMIN.GET_REFERRALS, adminGetReferralsSaga);
  yield takeLatest(actionTypes.ADMIN.ADD_REFERRAL, adminCreateReferralSaga);
  yield takeLatest(actionTypes.ADMIN.EDIT_REFERRAL, adminEditReferralSaga);
  yield takeLatest(actionTypes.ADMIN.REMOVE_REFERRAL, adminRemoveReferralSaga);
  yield takeLatest(actionTypes.ADMIN.GET_POSTS, adminGetPostsSaga);
  yield takeLatest(actionTypes.ADMIN.CREATE_POST, adminCreatePostSaga);
  yield takeLatest(actionTypes.ADMIN.EDIT_POST, adminEditPostSaga);
  yield takeLatest(actionTypes.ADMIN.REMOVE_POST, adminRemovePostSaga);
  yield takeLatest(actionTypes.ADMIN.UPDATE_POLICIES, adminUpdatePoliciesSaga);
  yield takeLatest(actionTypes.ADMIN.EXAMPLE_CONTRACT, adminGetExampleContractSaga);
  yield takeLatest(actionTypes.ADMIN.GET_INVOICES, adminGetInvoicesSaga);

  // APP
  yield takeLatest(actionTypes.APP.INITIALIZE, appInitSaga);
  yield takeLatest(actionTypes.APP.UPDATE, appUpdateSaga);
  yield takeLatest(actionTypes.APP.PREPARE_CHECKOUT, appCheckoutSaga);
  yield takeLatest(actionTypes.APP.CANCEL_SUBSCRIPTION, appCancelSaga);
  yield takeLatest(actionTypes.APP.COMPLETE_ONBOARDING, appCompleteOnboardingSaga);
  yield takeLatest(actionTypes.APP.GET_CONTACTS, appGetContactsSaga);
  yield takeLatest(actionTypes.APP.NEW_CONTACT, appNewContactSaga);
  yield takeLatest(actionTypes.APP.EDIT_CONTACT, appEditContactSaga);
  yield takeLatest(actionTypes.APP.REMOVE_CONTACT, appRemoveContactSaga);
  yield takeLatest(actionTypes.APP.CREATE_ALBUM, appNewAlbumSaga);
  yield takeLatest(actionTypes.APP.EDIT_ALBUM, appEditAlbumSaga);
  yield takeLatest(actionTypes.APP.REMOVE_ALBUM, appRemoveAlbumSaga);
  yield takeLatest(actionTypes.APP.UPLOAD_PHOTOS, appUploadPhotosSaga);
  yield takeLatest(actionTypes.APP.EDIT_PHOTO, appEditPhotoSaga);
  yield takeLatest(actionTypes.APP.REMOVE_PHOTO, appRemovePhotoSaga);
  yield takeLatest(actionTypes.APP.GET_EXPENSES, appGetExpensesSaga);
  yield takeLatest(actionTypes.APP.SUBMIT_EXPENSE, appSubmitExpenseSaga);
  yield takeLatest(actionTypes.APP.EDIT_EXPENSE, appEditExpenseSaga);
  yield takeLatest(actionTypes.APP.REMOVE_EXPENSE, appRemoveExpenseSaga);
  yield takeLatest(actionTypes.APP.REIMBURSE_EXPENSE, appReimburseExpenseSaga);
  yield takeLatest(actionTypes.APP.CHANGE_PASSWORD, appChangePasswordSaga);
  yield takeLatest(actionTypes.APP.CREATE_EVENT, appCreateEventSaga);
  yield takeLatest(actionTypes.APP.EDIT_EVENT, appEditEventSaga);
  yield takeLatest(actionTypes.APP.REMOVE_EVENT, appRemoveEventSaga);

  // CLIENT
  yield takeLatest(actionTypes.CLIENT.GET_PROFILE, clientGetProfileSaga);
  yield takeLatest(actionTypes.CLIENT.EDIT_PROFILE, clientEditProfileSaga);
  yield takeLatest(actionTypes.CLIENT.REMOVE_PROFILE, clientRemoveProfileSaga);

  // GUIDE
  yield takeLatest(actionTypes.GUIDE.GET_PROFILE, guideGetProfileSaga);
  yield takeLatest(actionTypes.GUIDE.EDIT_PROFILE, guideEditProfileSaga);

  // TRIP
  yield takeLatest(actionTypes.TRIP.FETCH_ADMIN, tripAdminFetchSaga);
  yield takeLatest(actionTypes.TRIP.FETCH_GUIDE, tripGuideFetchSaga);
  yield takeLatest(actionTypes.TRIP.FETCH_CLIENT, tripClientFetchSaga);
  yield takeLatest(actionTypes.TRIP.CREATE, tripCreateSaga);
  yield takeLatest(actionTypes.TRIP.EDIT, tripEditSaga);
  yield takeLatest(actionTypes.TRIP.REMOVE, tripRemoveSaga);
  yield takeLatest(actionTypes.TRIP.RESEND, tripResendSaga);
  yield takeLatest(actionTypes.TRIP.PAYMENT_ADD, tripAddPaymentSaga);
  yield takeLatest(actionTypes.TRIP.PAYMENT_EDIT, tripEditPaymentSaga);
  yield takeLatest(actionTypes.TRIP.PAYMENT_REMOVE, tripRemovePaymentSaga);
  yield takeLatest(actionTypes.TRIP.TRAVEL_PLANS, tripTravelSaga);
  yield takeLatest(actionTypes.TRIP.SIGN_CONTRACT, tripSignContractSaga);
  yield takeLatest(actionTypes.TRIP.DOWLOAD_CONTRACT, tripDownloadContractSaga);
}
