import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Edit from '@mui/icons-material/Edit';

const Referral = props => {
  const { name, phone, email, location, website, note, type, edit } = props;

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={name}
        subheader={type ?? 'taxidermist'}
        subheaderTypographyProps={{ sx: { textTransform: 'capitalize' } }}
        action={
          !!edit ? (
            <IconButton color="secondary" onClick={edit} sx={{ ml: 2 }} aria-label="edit">
              <Edit />
            </IconButton>
          ) : null
        }
      />
      <CardContent>
        <Grid container columnSpacing={1}>
          {phone ? (
            <Grid item xs={12} md={6} lg={12} xl={6}>
              <Stack alignItems="start" spacing={0} sx={{ minWidth: 105 }}>
                <Typography variant="body2">Phone</Typography>
                <Divider flexItem color="#000" />
                <Link sx={{ px: 1, alignSelf: 'end' }} href={`tel:${phone}`}>
                  {phone}
                </Link>
              </Stack>
            </Grid>
          ) : null}
          {email ? (
            <Grid item xs={12} md={6} lg={12} xl={6}>
              <Stack alignItems="start" spacing={0} sx={{ minWidth: 105 }}>
                <Typography variant="body2">Email</Typography>
                <Divider flexItem color="#000" />
                <Link sx={{ px: 1, alignSelf: 'end' }} href={`mailto:${email}`}>
                  {email}
                </Link>
              </Stack>
            </Grid>
          ) : null}
          {website ? (
            <Grid item xs={12} md={6} lg={12} xl={6}>
              <Stack alignItems="start" spacing={0} sx={{ minWidth: 105 }}>
                <Typography variant="body2">Website</Typography>
                <Divider flexItem color="#000" />
                <Link sx={{ px: 1, alignSelf: 'end' }} href={website}>
                  {website}
                </Link>
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={12} md={6} lg={12} xl={6}>
            <Stack alignItems="start" spacing={0} sx={{ minWidth: 105 }}>
              <Typography variant="body2">Location</Typography>
              <Divider flexItem color="#000" />
              <Typography sx={{ px: 1, alignSelf: 'end' }}>{location}</Typography>
            </Stack>
          </Grid>
          {note ? (
            <Grid item xs={12}>
              <Stack alignItems="start" spacing={0} sx={{ minWidth: 105 }}>
                <Typography variant="body2">Notes</Typography>
                <Divider flexItem color="#000" />
                <Typography sx={{ px: 1, alignSelf: 'end' }}>{note}</Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Referral;
