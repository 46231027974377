import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Post from '../../components/Post';
import Logo from '../Photos/Logo/Display';
import BackNavigation from '../../components/Interface/BackNavigation';
import ContactButtons from '../../components/Interface/ContactButtons';

const PostList = () => {
  const { posts, userType, phone, email } = useSelector(state => state.app);
  const route = useNavigate();

  return (
    <Stack sx={{ width: '100%', maxWidth: 600 }} spacing={2}>
      {userType === 'admin' ? <BackNavigation text="Back To Dashboard" url="/" /> : null}
      {userType !== 'admin' ? <Logo /> : null}
      <Stack direction="row" justifyContent="space-between" sx={{ width: 'inherit' }}>
        <Typography variant={userType === 'admin' ? 'h5' : 'h4'}>Posts</Typography>
        {userType === 'admin' ? (
          <Button sx={{ ml: 4 }} onClick={() => route('/posts/create')}>
            Create
          </Button>
        ) : (
          <ContactButtons phone={phone} email={email} />
        )}
      </Stack>
      {posts?.length === 0 ? (
        <Typography>{userType === 'admin' ? 'Create a Post' : 'No Posts At This Time'}</Typography>
      ) : (
        posts
          ?.sort((a, b) => (a.posted > b.posted ? -1 : 1))
          ?.map(post => (
            <Post
              key={post._id}
              {...post}
              edit={userType !== 'admin' ? null : () => route('/posts/edit/' + post._id)}
            />
          ))
      )}
    </Stack>
  );
};

export default PostList;
