import * as actions from '../actions';
import { put } from 'redux-saga/effects';
import server from '../../assets/axios';

export function* appInitSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.get('/app', { params: { userType: action.data } });

    if (status === 200) {
      yield put(actions.appInitializeSuccess(data));

      switch (data.userType) {
        case 'admin':
          yield put(actions.adminGetProfileSuccess(data.user));
          break;
        case 'guide':
          yield put(actions.guideGetProfileSuccess(data.user));
          break;
        default:
          yield put(actions.clientGetProfileSuccess(data.user));
      }
    } else {
      yield put(actions.appError(data?.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appUpdateSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.put('/app', action.data);

    if (status === 200) {
      yield put(actions.appUpdateSuccess(data));
    } else {
      yield put(actions.appError(data?.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appCheckoutSaga() {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.get('/app/payment');

    if (status === 200) {
      yield put(actions.appPrepareCheckoutSuccess(data));
    } else {
      yield put(actions.appError(data));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appCancelSaga() {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.delete('/app/payment');

    if (status === 200) {
      yield put(actions.appCancelSubscriptionSuccess());
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appCompleteOnboardingSaga() {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.post('/app/onboard');

    if (status === 200) {
      yield put(actions.appCompleteOnboardingSuccess());
    } else {
      yield put(actions.appError(data?.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appGetContactsSaga() {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.get('/app/contacts');

    if (status === 200) {
      yield put(actions.appGetContactsSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appNewContactSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.post('/app/contacts', action.data);

    if (status === 200) {
      yield put(actions.appNewContactSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appEditContactSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.put('/app/contacts', action.data);

    if (status === 200) {
      yield put(actions.appEditContactSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appRemoveContactSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.delete('/app/contacts', { data: { contact: action.data } });

    if (status === 200) {
      yield put(actions.appRemoveContactSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appNewAlbumSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.post('/albums', action.data);

    if (status === 201) {
      yield put(actions.appNewAlbumSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appEditAlbumSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.put('/albums', action.data);

    if (status === 200) {
      yield put(actions.appEditAlbumSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appRemoveAlbumSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.delete('/albums', { data: { album: action.data } });

    if (status === 200) {
      yield put(actions.appRemoveAlbumSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appUploadPhotosSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.post('/albums/photos', action.data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (status === 200) {
      yield put(actions.appUploadPhotosSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appEditPhotoSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.put('/albums/photos', action.data);

    if (status === 200) {
      yield put(actions.appEditPhotoSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appRemovePhotoSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.delete('/albums/photos', { data: action.data });

    if (status === 200) {
      yield put(actions.appRemovePhotoSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}
export function* appGetExpensesSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.get('/app/expenses', { params: { type: action.data } });

    if (status === 200) {
      yield put(actions.appGetExpensesSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appSubmitExpenseSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.post('/app/expenses', action.data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (status === 200) {
      yield put(actions.appSubmitExpenseSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appEditExpenseSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.put('/app/expenses', action.data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (status === 200) {
      yield put(actions.appEditExpenseSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appRemoveExpenseSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.delete('/app/expenses', { data: action.data });

    if (status === 200) {
      yield put(actions.appRemoveExpenseSuccess(action.data.expense));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appReimburseExpenseSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.put('/app/expenses/paid', action.data);

    if (status === 200) {
      yield put(actions.appReimburseExpenseSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appChangePasswordSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.get('/app/change-password', { params: { email: action.data } });

    if (status === 200) {
      yield put(actions.appChangePasswordSuccess());
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appCreateEventSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.post('/events', action.data);

    if (status === 200) {
      yield put(actions.appCreateEventSuccess(data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appEditEventSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.put('/events', action.data);

    if (status === 200) {
      yield put(actions.appEditEventSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}

export function* appRemoveEventSaga(action) {
  yield put(actions.appInit());
  try {
    const { status, data } = yield server.delete('/events', { data: { event: action.data } });

    if (status === 200) {
      yield put(actions.appRemoveEventSuccess(action.data));
    } else {
      yield put(actions.appError(data.error));
    }
  } catch (err) {
    yield put(actions.appError(err.message));
  }
}
