import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import AuthRoute from '../utility/AuthRoute';

// Routes that are not Lazy Loaded
import Auth from './Auth';
import Home from './Home';

// Lazy Loaded Routes by category

// General App
const About = lazy(() => import('../components/About'));
const TripList = lazy(() => import('./Trip/List'));
const TripCreate = lazy(() => import('./Trip/Create'));
const TripEdit = lazy(() => import('./Trip/Edit'));
const TripViewAdmin = lazy(() => import('./AdminAndGuide/TripView'));
const PhotoAlbums = lazy(() => import('./Photos/Albums'));
const PhotoDisplay = lazy(() => import('./Photos/Display'));
const PhotoUpload = lazy(() => import('./Photos/Upload'));
const PhotoEdit = lazy(() => import('./Photos/Edit'));
const LogoUpload = lazy(() => import('./Photos/Logo/UploadLogo'));

// Admin Routes and Guide Routes
const AdminAndGuideProfile = lazy(() => import('./AdminAndGuide/Information'));
const Subscription = lazy(() => import('./StripeSubscription'));
const Users = lazy(() => import('./Users/List'));
const CreateUser = lazy(() => import('./Users/Create'));
const UserProfile = lazy(() => import('./Users/Profile'));
const TripTypeList = lazy(() => import('./TripTypes/List'));
const TripTypeCreate = lazy(() => import('./TripTypes/Create'));
const TripTypeEdit = lazy(() => import('./TripTypes/Edit'));
const Contacts = lazy(() => import('./Contacts'));
const NewContact = lazy(() => import('./Contacts/Create'));
const EditContact = lazy(() => import('./Contacts/Edit'));
const Expenses = lazy(() => import('./Expenses/Submitted'));
const CreateExpense = lazy(() => import('./Expenses/Submit'));
const EditExpense = lazy(() => import('./Expenses/Edit'));
const ReferralList = lazy(() => import('./Referrals/List'));
const AddReferral = lazy(() => import('./Referrals/Add'));
const EditReferral = lazy(() => import('./Referrals/Edit'));
const CustomPolices = lazy(() => import('./AdminAndGuide/Policies'));
const PostList = lazy(() => import('./Post/List'));
const PostCreate = lazy(() => import('./Post/Create'));
const PostEdit = lazy(() => import('./Post/Edit'));
const InactiveGuideMessage = lazy(() => import('./InactiveGuideMessage'));

// Client Routes
const TripOverview = lazy(() => import('./ClientTripView'));
const ClientPhotos = lazy(() => import('./ClientTripView/Photos'));
const ClientProfile = lazy(() => import('./Client/Information'));

// Drawer Menu routes can be found in ../components/Header.jsx
const Onboard = lazy(() => import('./AdminAndGuide/Onboard'));

export default function Router(props) {
  const { userType, isAuthenticated } = props;

  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        {/* All Access Routes */}
        <>
          <Route path="/" element={<Home userType={userType} auth={isAuthenticated} />} />
          <Route path="/about" element={<About userType={userType} auth={isAuthenticated} />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/signup" element={<Auth />} />
          <Route path="/logout" element={<Auth />} />
          {(() => {
            switch (userType) {
              case 'admin': {
                return (
                  <>
                    <Route path="/contacts" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/contacts" element={<Contacts />} />
                      <Route path="/contacts/create" element={<NewContact />} />
                      <Route path="/contacts/edit/:id" element={<EditContact />} />
                    </Route>
                    <Route path="/expenses" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/expenses" element={<Expenses />} />
                      <Route path="/expenses/create" element={<CreateExpense />} />
                      <Route path="/expenses/edit/:id" element={<EditExpense />} />
                    </Route>
                    <Route path="/logo" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/logo" element={<LogoUpload />} />
                    </Route>
                    <Route path="/onboard" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/onboard" element={<Onboard />} />
                    </Route>
                    <Route path="/photos" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/photos" element={<PhotoAlbums />} />
                      <Route path="/photos/album/:id" element={<PhotoDisplay />} />
                      <Route path="/photos/upload/:album" element={<PhotoUpload />} />
                      <Route path="/photos/album/:album/edit/:id" element={<PhotoEdit />} />
                    </Route>
                    <Route path="/policies" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/policies" element={<CustomPolices />} />
                    </Route>
                    <Route path="/posts" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/posts" element={<PostList />} />
                      <Route path="/posts/create" element={<PostCreate />} />
                      <Route path="/posts/edit/:id" element={<PostEdit />} />
                    </Route>
                    <Route path="/profile" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/profile" element={<AdminAndGuideProfile />} />
                    </Route>
                    <Route path="/subscription" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/subscription" element={<Subscription />} />
                    </Route>
                    <Route path="/referrals" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/referrals" element={<ReferralList />} />
                      <Route path="/referrals/create" element={<AddReferral />} />
                      <Route path="/referrals/edit/:id" element={<EditReferral />} />
                    </Route>
                    <Route path="/trips" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/trips" element={<TripList />} />
                      <Route path="/trips/:id" element={<TripViewAdmin />} />
                      <Route path="/trips/create" element={<TripCreate />} />
                      <Route path="/trips/edit/:id" element={<TripEdit />} />
                      <Route path="/trips/types" element={<TripTypeList />} />
                      <Route path="/trips/types/create" element={<TripTypeCreate />} />
                      <Route path="/trips/types/edit/:id" element={<TripTypeEdit />} />
                    </Route>
                    <Route path="/users" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/users" element={<Users />} />
                      <Route path="/users/create" element={<CreateUser />} />
                      <Route path="/users/:userType/:id" element={<UserProfile />} />
                    </Route>
                  </>
                );
              }
              case 'guide': {
                return (
                  <>
                    <Route path="/contacts" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/contacts" element={<Contacts />} />
                      <Route path="/contacts/create" element={<NewContact />} />
                      <Route path="/contacts/edit/:id" element={<EditContact />} />
                    </Route>
                    <Route path="/expenses" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/expenses" element={<Expenses />} />
                      <Route path="/expenses/create" element={<CreateExpense />} />
                      <Route path="/expenses/edit/:id" element={<EditExpense />} />
                    </Route>
                    <Route path="/trips" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/trips/:id" element={<TripViewAdmin />} />
                    </Route>
                    <Route path="/referrals" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/referrals" element={<ReferralList />} />
                    </Route>
                    <Route path="/posts" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/posts" element={<PostList />} />
                    </Route>
                    <Route path="/photos" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/photos" element={<PhotoAlbums />} />
                      <Route path="/photos/album/:id" element={<PhotoDisplay />} />
                      <Route path="/photos/upload/:album" element={<PhotoUpload />} />
                      <Route path="/photos/album/:album/edit/:id" element={<PhotoEdit />} />
                    </Route>
                    <Route path="/profile" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/profile" element={<AdminAndGuideProfile />} />
                    </Route>
                    <Route path="/message" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/message" element={<InactiveGuideMessage />} />
                    </Route>
                  </>
                );
              }
              default: {
                return (
                  <>
                    <Route path="/overview/:id/*" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="*" element={<TripOverview />} />
                    </Route>
                    <Route path="/trips" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/trips" element={<TripList />} />
                    </Route>
                    <Route path="/photos" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/photos" element={<ClientPhotos />} />
                    </Route>
                    <Route path="/profile" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/profile" element={<ClientProfile />} />
                    </Route>
                    <Route path="/referrals" element={<AuthRoute auth={isAuthenticated} />}>
                      <Route path="/referrals" element={<ReferralList />} />
                    </Route>
                  </>
                );
              }
            }
          })()}
          <Route path="*" element={<Navigate to="/" />} />
        </>
      </Routes>
    </Suspense>
  );
}
