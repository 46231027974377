import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ListPreview = props => {
  const { title, routeToAll, Component, list } = props;

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack sx={{ width: '100%', maxWidth: 900 }}>
        <Stack direction="row" spacing={4} justifyContent="space-between" sx={{ width: '100%' }}>
          {/* <Typography variant="h5">{title}</Typography> */}
          <Button onClick={routeToAll}>Go To {title}</Button>
        </Stack>
        {(list?.length ?? 0) === 0 ? (
          <Typography>No Data</Typography>
        ) : (
          list?.slice(0, 2).map(item => <Component key={item._id} {...item} />)
        )}
        <Typography align="right" sx={{ width: '100%' }} variant="h6">
          Total: {list?.length ?? 0}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ListPreview;
