import React from 'react';
import dayjs from 'dayjs';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Edit from '@mui/icons-material/Edit';

const Post = props => {
  const { title, body, posted, edited, edit } = props;

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={title}
        subheader={dayjs(edited ?? posted).format('MMM D, YYYY h:mm a')}
        subheaderTypographyProps={{ variant: 'body2' }}
        action={
          !!edit ? (
            <IconButton color="secondary" onClick={edit} sx={{ ml: 2 }} aria-label="edit">
              <Edit />
            </IconButton>
          ) : null
        }
      />
      <CardContent>
        <Typography>{body}</Typography>
      </CardContent>
    </Card>
  );
};

export default Post;
