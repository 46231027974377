import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Container from '@mui/material/Container';

import Accomm from '@mui/icons-material/NightShelter';
import Contract from '@mui/icons-material/Receipt';
import Gear from '@mui/icons-material/Backpack';
import Info from '@mui/icons-material/Info';
import Payment from '@mui/icons-material/AttachMoney';
import Photo from '@mui/icons-material/Photo';
// import Weather from '@mui/icons-material/Thermostat';

const Navigation = forwardRef(({ style }, transitionRef) => {
  const trips = useSelector(state => state.trip.trips);
  const { pathname } = useLocation();
  const route = useNavigate();

  const [value, setValue] = useState('/');
  const [trip, setTrip] = useState(null);
  const [accom, setAccom] = useState(false);

  useEffect(() => {
    if (pathname.includes('/payment')) {
      setValue('/overview/' + trip + '/payment');
    } else if (pathname.includes('/info')) {
      setValue('/overview/' + trip + '/info');
    } else if (pathname.includes('/gear')) {
      setValue('/overview/' + trip + '/gear');
    } else if (pathname.includes('/accommodation')) {
      setValue('/overview/' + trip + '/accommodation');
    // } else if (pathname.includes('/weather')) {
    //   setValue('/overview/' + trip + '/weather');
    } else if (pathname.includes('/photos')) {
      setValue('/overview/' + trip + '/photos');
    } else {
      setValue('/overview/' + trip);
    }
  }, [pathname, trip]);

  useEffect(() => {
    setTrip(prev => {
      if (prev !== null) {
        return prev;
      } else {
        return pathname.split('/')[2];
      }
    });
  }, [pathname]);

  useEffect(() => {
    const selected = trips.find(tr => tr._id === trip);

    setAccom(selected?.type?.accommodations?.provided ?? false);
  }, [trips, trip]);

  const navigate = (_, newValue) => {
    route(newValue);
  };

  return (
    <Container
      sx={{ backgroundColor: 'header.main', position: 'fixed', bottom: 0, zIndex: 10 }}
      style={style}
      ref={transitionRef}
    >
      <BottomNavigation showLabels value={value} onChange={navigate}>
        <BottomNavigationAction label="Contract" icon={<Contract fontSize="small" />} value={`/overview/${trip}`} />
        <BottomNavigationAction
          label="Payment"
          icon={<Payment fontSize="small" />}
          value={`/overview/${trip}/payment`}
        />
        <BottomNavigationAction label="Info" icon={<Info fontSize="small" />} value={`/overview/${trip}/info`} />
        <BottomNavigationAction label="Gear" icon={<Gear fontSize="small" />} value={`/overview/${trip}/gear`} />
        {accom && <BottomNavigationAction label="Accom" icon={<Accomm fontSize="small" />} value={`/overview/${trip}/accommodation`} />}
        <BottomNavigationAction label="Photos" icon={<Photo fontSize="small" />} value={`/overview/${trip}/photos`} />
        {/* <BottomNavigationAction
            label="Weather"
            icon={<Weather fontSize="small" />}
            value={`/overview/${trip}/weather`}
          /> */}
      </BottomNavigation>
    </Container>
  );
});

export default Navigation;
