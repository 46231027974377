import * as actions from '../actions';
import { put } from 'redux-saga/effects';
import server from '../../assets/axios';

export function* guideGetProfileSaga() {
  yield put(actions.guideInit());
  try {
    const { status, data } = yield server.get('/guide');

    if (status === 200) {
      yield put(actions.guideGetProfileSuccess(data));
    } else {
      yield put(actions.guideError(data.error));
    }
  } catch (err) {
    yield put(actions.guideError(err.message));
  }
}

export function* guideEditProfileSaga(action) {
  yield put(actions.guideInit());
  try {
    const { status, data } = yield server.put('/guide', action.data);

    if (status === 200) {
      yield put(actions.guideEditProfileSuccess(data));
    } else {
      yield put(actions.guideError(data.error));
    }
  } catch (err) {
    yield put(actions.guideError(err.message));
  }
}
