import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const LogoDisplay = () => {
  const { logo, logoCache, userType } = useSelector(state => state.app);
  const route = useNavigate();



  return userType === 'admin' ? (
    logo === null ? (
      <Stack onClick={() => route('/logo')} sx={{ '&:hover': { cursor: 'pointer' } }} spacing={0}>
        <Avatar src="/eo_192.png" alt="Default Easy Outfitting Logo" variant="square" />
        <Typography variant="caption" color="secondary">
          Click to Add Your Logo
        </Typography>
      </Stack>
    ) : (
      <Stack onClick={() => route('/logo')} sx={{ '&:hover': { cursor: 'pointer' } }} spacing={0}>
        <img
          src={process.env.REACT_APP_BUCKET + logo + '?cache=' + logoCache}
          alt={process.env.REACT_APP_INSTANCE_NAME + ' Logo'}
          style={{ maxHeight: 125 }}
        />
        <Typography variant="caption" color="secondary">
          Click to Change
        </Typography>
      </Stack>
    )
  ) : logo === null ? (
    <Avatar src="/eo_192.png" alt="Default Easy Outfitting Logo" variant="square" sx={{ width: 100, height: 100 }} />
  ) : (
    <img
      src={process.env.REACT_APP_BUCKET + logo + '?cache=' + logoCache}
      alt={process.env.REACT_APP_INSTANCE_NAME + ' Logo'}
      style={{ maxHeight: 125 }}
    />
  );
};

export default LogoDisplay;
