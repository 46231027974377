import React from 'react';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { countries, states } from '../../assets/constants';

const AddressForm = props => {
  const { formik } = props;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          id="street"
          name="street"
          label="Street Address"
          value={formik.values.street}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.street && Boolean(formik.errors.street)}
          helperText={formik.touched.street && formik.errors.street}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="suite"
          name="suite"
          label="Apartment or Suite"
          value={formik.values.suite}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.suite && Boolean(formik.errors.suite)}
          helperText={formik.touched.suite && formik.errors.suite}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="city"
          name="city"
          label="City"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="postal"
          name="postal"
          label="Postal or Zip Code"
          value={formik.values.postal}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.postal && Boolean(formik.errors.postal)}
          helperText={formik.touched.postal && formik.errors.postal}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="state"
          name="state"
          label="Province or State"
          select
          InputLabelProps={{
            htmlFor: undefined,
          }}
          value={formik.values.state}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
        >
          {states.map(state => (
            <MenuItem key={state.value} value={state.value}>
              {state.displayValue}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="country"
          name="country"
          label="Country"
          select
          autoComplete='country'
          InputLabelProps={{
            htmlFor: undefined,
          }}
          value={formik.values.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.country && Boolean(formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}
        >
          {countries.map(country => (
            <MenuItem key={country.value} value={country.value}>
              {country.displayValue}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};

export default AddressForm;
