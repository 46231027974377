export const ADMIN = {
  INIT: 'ADMIN_INIT',
  ERROR: 'ADMIN_ERROR',

  // PROFILE MANAGEMENT
  GET_PROFILE: 'ADMIN_GET_PROFILE',
  GET_PROFILE_SUCCESS: 'ADMIN_GET_PROFILE_SUCCESS',

  EDIT_PROFILE: 'ADMIN_EDIT_PROFILE',
  EDIT_PROFILE_SUCCESS: 'ADMIN_EDIT_PROFILE_SUCCESS',

  // LOGO
  UPLOAD_LOGO: 'ADMIN_UPLOAD_LOGO',
  UPLOAD_LOGO_SUCCESS: 'ADMIN_UPLOAD_LOGO_SUCCESS',

  // USER MANAGEMENT
  GET_ALL_USERS: 'ADMIN_GET_ALL_USERS',
  GET_ALL_USERS_SUCCESS: 'ADMIN_GET_ALL_USERS_SUCCESS',

  GET_USER: 'ADMIN_GET_USER',
  GET_USER_SUCCESS: 'ADMIN_GET_USER_SUCCESS',

  CREATE_USER: 'ADMIN_CREATE_USER',
  CREATE_USER_SUCCESS: 'ADMIN_CREATE_USER_SUCCESS',

  EDIT_USER: 'ADMIN_EDIT_USER',
  EDIT_USER_SUCCESS: 'ADMIN_EDIT_USER_SUCCESS',

  REMOVE_USER: 'ADMIN_REMOVE_USER',
  REMOVE_USER_SUCCESS: 'ADMIN_REMOVE_USER_SUCCESS',

  SEARCH_USERS: 'ADMIN_SEARCH_USERS',
  SEARCH_USERS_SUCCESS: 'ADMIN_SEARCH_USERS_SUCCESS',

  CLEAR_SELECTED_USER: 'ADMIN_CLEAR_SELECTED_USER',

  CLIENT_WIN: 'ADMIN_CLIENT_WIN',
  CLIENT_WIN_SUCCESS: 'ADMIN_CLIENT_WIN_SUCCESS',

  // TRIP TYPE MANAGEMENT
  GET_TRIP_TYPE: 'ADMIN_GET_TRIP_TYPE',
  GET_TRIP_TYPE_SUCCESS: 'ADMIN_GET_TRIP_TYPE_SUCCESS',

  CREATE_TRIP_TYPE: 'ADMIN_CREATE_TRIP_TYPE',
  CREATE_TRIP_TYPE_SUCCESS: 'ADMIN_CREATE_TRIP_TYPE_SUCCESS',

  EDIT_TRIP_TYPE: 'ADMIN_EDIT_TRIP_TYPE',
  EDIT_TRIP_TYPE_SUCCESS: 'ADMIN_EDIT_TRIP_TYPE_SUCCESS',

  REMOVE_TRIP_TYPE: 'ADMIN_REMOVE_TRIP_TYPE',
  REMOVE_TRIP_TYPE_SUCCESS: 'ADMIN_REMOVE_TRIP_TYPE_SUCCESS',

  // REFERRALS
  GET_REFERRALS: 'ADMIN_GET_REFERRALS',
  GET_REFERRALS_SUCCESS: 'ADMIN_GET_REFERRALS_SUCCESS',

  ADD_REFERRAL: 'ADMIN_ADD_REFERRAL',
  ADD_REFERRAL_SUCCESS: 'ADMIN_ADD_REFERRAL_SUCCESS',

  EDIT_REFERRAL: 'ADMIN_EDIT_REFERRAL',
  EDIT_REFERRAL_SUCCESS: 'ADMIN_EDIT_REFERRAL_SUCCESS',

  REMOVE_REFERRAL: 'ADMIN_REMOVE_REFERRAL',
  REMOVE_REFERRAL_SUCCESS: 'ADMIN_REMOVE_REFERRAL_SUCCESS',

  // POSTS
  GET_POSTS: 'ADMIN_GET_POSTS',
  GET_POSTS_SUCCESS: 'ADMIN_GET_POSTS_SUCCESS',

  CREATE_POST: 'ADMIN_CREATE_POST',
  CREATE_POST_SUCCESS: 'ADMIN_CREATE_POST_SUCCESS',

  EDIT_POST: 'ADMIN_EDIT_POST',
  EDIT_POST_SUCCESS: 'ADMIN_EDIT_POST_SUCCESS',

  REMOVE_POST: 'ADMIN_REMOVE_POST',
  REMOVE_POST_SUCCESS: 'ADMIN_REMOVE_POST_SUCCESS',

  UPDATE_POLICIES: 'ADMIN_UPDATE_POLICIES',
  UPDATE_POLICIES_SUCCESS: 'ADMIN_UPDATE_POLICIES_SUCCESS',

  EXAMPLE_CONTRACT: 'ADMIN_EXAMPLE_CONTRACT',
  EXAMPLE_CONTRACT_SUCCESS: 'ADMIN_EXAMPLE_CONTRACT_SUCCESS',

  // STRIPE INVOICES
  GET_INVOICES: 'GET_INVOICES',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',

  CLEAR_MSG: 'ADMIN_CLEAR_MSG',
  CLEAR_ERR: 'ADMIN_CLEAR_ERR',
};

export const APP = {
  INIT: 'APP_INIT',
  ERROR: 'APP_ERROR',

  INITIALIZE: 'APP_INITIALIZE',
  INITIALIZE_SUCCESS: 'APP_INITIALIZE_SUCCESS',

  // INFO
  UPDATE: 'APP_UPDATE',
  UPDATE_SUCCESS: 'APP_UPDATE_SUCCESS',

  // STRIPE
  PREPARE_CHECKOUT: 'APP_PREPARE_CHECKOUT',
  PREPARE_CHECKOUT_SUCCESS: 'APP_PREPARE_CHECKOUT_SUCCESS',

  CANCEL_SUBSCRIPTION: 'APP_CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_SUCCESS: 'APP_CANCEL_SUBSCRIPTION_SUCCESS',

  // ONBOARDING
  COMPLETE_ONBOARDING: 'APP_COMPLETE_ONBOARDING',
  COMPLETE_ONBOARDING_SUCCESS: 'APP_COMPLETE_ONBOARDING_SUCCESS',

  // CONTACTS
  GET_CONTACTS: 'APP_GET_CONTACTS',
  GET_CONTACTS_SUCCESS: 'APP_GET_CONTACTS_SUCCESS',

  NEW_CONTACT: 'APP_NEW_CONTACT',
  NEW_CONTACT_SUCCESS: 'APP_NEW_CONTACT_SUCCESS',

  EDIT_CONTACT: 'APP_EDIT_CONTACT',
  EDIT_CONTACT_SUCCESS: 'APP_EDIT_CONTACT_SUCCESS',

  REMOVE_CONTACT: 'APP_REMOVE_CONTACT',
  REMOVE_CONTACT_SUCCESS: 'APP_REMOVE_CONTACT_SUCCESS',

  // PHOTOS
  CREATE_ALBUM: 'APP_CREATE_ALBUM',
  CREATE_ALBUM_SUCCESS: 'APP_CREATE_ALBUM_SUCCESS',

  EDIT_ALBUM: 'APP_EDIT_ALBUM',
  EDIT_ALBUM_SUCCESS: 'APP_EDIT_ALBUM_SUCCESS',

  REMOVE_ALBUM: 'APP_REMOVE_ALBUM',
  REMOVE_ALBUM_SUCCESS: 'APP_REMOVE_ALBUM_SUCCESS',

  UPLOAD_PHOTOS: 'APP_UPLOAD_PHOTOS',
  UPLOAD_PHOTOS_SUCCESS: 'APP_UPLOAD_PHOTOS_SUCCESS',

  EDIT_PHOTO: 'APP_EDIT_PHOTO',
  EDIT_PHOTO_SUCCESS: 'APP_EDIT_PHOTO_SUCCESS',

  REMOVE_PHOTO: 'APP_REMOVE_PHOTO',
  REMOVE_PHOTO_SUCCESS: 'APP_REMOVE_PHOTO_SUCCESS',

  // EXPENSES
  GET_EXPENSES: 'APP_GET_EXPENSES',
  GET_EXPENSES_SUCCESS: 'APP_GET_EXPENSES_SUCCESS',

  SUBMIT_EXPENSE: 'APP_SUBMIT_EXPENSE',
  SUBMIT_EXPENSE_SUCCESS: 'APP_SUBMIT_EXPENSE_SUCCESS',

  EDIT_EXPENSE: 'APP_EDIT_EXPENSE',
  EDIT_EXPENSE_SUCCESS: 'APP_EDIT_EXPENSE_SUCCESS',

  REMOVE_EXPENSE: 'APP_REMOVE_EXPENSE',
  REMOVE_EXPENSE_SUCCESS: 'APP_REMOVE_EXPENSE_SUCCESS',

  REIMBURSE_EXPENSE: 'APP_REIMBURSE_EXPENSE',
  REIMBURSE_EXPENSE_SUCCESS: 'APP_REIMBURSE_EXPENSE_SUCCESS',

  // REQUEST AN AUTH0 PASSWORD CHANGE EMAIL, USED BY ANY USER
  CHANGE_PASSWORD: 'APP_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'APP_CHANGE_PASSWORD_SUCCESS',

  // CUSTOM EVENTS
  CREATE_EVENT: 'APP_CREATE_EVENT',
  CREATE_EVENT_SUCCESS: 'APP_CREATE_EVENT_SUCCESS',

  EDIT_EVENT: 'APP_EDIT_EVENT',
  EDIT_EVENT_SUCCESS: 'APP_EDIT_EVENT_SUCCESS',

  REMOVE_EVENT: 'APP_REMOVE_EVENT',
  REMOVE_EVENT_SUCCESS: 'APP_REMOVE_EVENT_SUCCESS',

  CLEAR_MSG: 'APP_CLEAR_MSG',
  CLEAR_ERR: 'APP_CLEAR_ERR',
};

export const CLIENT = {
  INIT: 'CLIENT_INIT',
  ERROR: 'CLIENT_ERROR',

  GET_PROFILE: 'CLIENT_GET_PROFILE',
  GET_PROFILE_SUCCESS: 'CLIENT_GET_PROFILE_SUCCESS',

  EDIT_PROFILE: 'CLIENT_EDIT_PROFILE',
  EDIT_PROFILE_SUCCESS: 'CLIENT_EDIT_PROFILE_SUCCESS',

  REMOVE_PROFILE: 'CLIENT_REMOVE_PROFILE',
  REMOVE_PROFILE_SUCCESS: 'CLIENT_REMOVE_PROFILE_SUCCESS',

  CLEAR_MSG: 'CLIENT_CLEAR_MSG',
  CLEAR_ERR: 'CLIENT_CLEAR_ERR',
};

export const GUIDE = {
  INIT: 'GUIDE_INIT',
  ERROR: 'GUIDE_ERROR',

  // PROFILE
  GET_PROFILE: 'GUIDE_GET_PROFILE',
  GET_PROFILE_SUCCESS: 'GUIDE_GET_PROFILE_SUCCESS',

  EDIT_PROFILE: 'GUIDE_EDIT_PROFILE',
  EDIT_PROFILE_SUCCESS: 'GUIDE_EDIT_PROFILE_SUCCESS',

  CLEAR_MSG: 'GUIDE_CLEAR_MSG',
  CLEAR_ERR: 'GUIDE_CLEAR_ERR',
};

export const TRIP = {
  INIT: 'TRIP_INIT',
  ERROR: 'TRIP_ERROR',

  CREATE: 'TRIP_CREATE',
  CREATE_SUCCESS: 'TRIP_CREATE_SUCCESS',

  EDIT: 'TRIP_EDIT',
  EDIT_SUCCESS: 'TRIP_EDIT_SUCCESS',

  REMOVE: 'TRIP_REMOVE',
  REMOVE_SUCCESS: 'TRIP_REMOVE_SUCCESS',

  RESEND: 'TRIP_RESEND',
  RESEND_SUCCESS: 'TRIP_RESEND_SUCCESS',

  FETCH_ADMIN: 'TRIP_FETCH_ADMIN',
  FETCH_GUIDE: 'TRIP_FETCH_GUIDE',
  FETCH_CLIENT: 'TRIP_FETCH_CLIENT',
  FETCH_SUCCESS: 'TRIP_FETCH_SUCCESS',

  PAYMENT_ADD: 'TRIP_PAYMENT_ADD',
  PAYMENT_EDIT: 'TRIP_PAYMENT_EDIT',
  PAYMENT_REMOVE: 'TRIP_PAYMENT_REMOVE',
  PAYMENT_SUCCESS: 'TRIP_PAYMENT_ADD_SUCCESS',

  TRAVEL_PLANS: 'TRIP_TRAVEL_PLANS',
  TRAVEL_PLANS_SUCCESS: 'TRIP_TRAVEL_PLANS_SUCCESS',

  SIGN_CONTRACT: 'TRIP_SIGN_CONTRACT',
  SIGN_CONTRACT_SUCCESS: 'TRIP_SIGN_CONTRACT_SUCCESS',

  DOWLOAD_CONTRACT: 'TRIP_DOWLOAD_CONTRACT',
  DOWLOAD_CONTRACT_SUCCESS: 'TRIP_DOWLOAD_CONTRACT_SUCCESS',

  CLEAR_MSG: 'TRIP_CLEAR_MSG',
  CLEAR_ERR: 'TRIP_CLEAR_ERR',
};
