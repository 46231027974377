import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Container from '@mui/material/Container';

import Calendar from '@mui/icons-material/CalendarMonth';
import Dashboard from '@mui/icons-material/Assessment';
import Group from '@mui/icons-material/Group';
import Home from '@mui/icons-material/Home';
import Phone from '@mui/icons-material/Phone';
import Photo from '@mui/icons-material/Photo';
import Receipt from '@mui/icons-material/Receipt';
import Store from '@mui/icons-material/Store';

const Navigation = forwardRef((props, transitionRef) => {
  const { auth, userType, style } = props;
  const { pathname } = useLocation();
  const route = useNavigate();

  const [value, setValue] = useState('/');

  useEffect(() => {
    if (pathname.includes('/contacts')) {
      setValue('/contacts');
    } else if (pathname.includes('/trips') && (userType === 'admin' || userType === 'client')) {
      setValue('/trips');
    } else if (pathname.includes('/trips') && userType === 'guide') {
      setValue('/');
    } else if (pathname === '/') {
      setValue('/');
    } else if (pathname.includes('/users')) {
      setValue('/users');
    } else if (pathname.includes('/photos')) {
      setValue('/photos');
    } else if (pathname.includes('/expenses')) {
      setValue('/expenses');
    } else if (pathname.includes('/referrals')) {
      setValue('/referrals');
    } else {
      setValue('');
    }
  }, [pathname, userType]);

  const navigate = (_, newValue) => {
    route(newValue);
  };

  return (
    <Container
      sx={{ backgroundColor: 'header.main', position: 'fixed', bottom: 0, zIndex: 10, p: 0 }}
      style={style}
      ref={transitionRef}
    >
      {auth ? (
        userType === 'admin' ? (
          <BottomNavigation showLabels value={value} onChange={navigate}>
            <BottomNavigationAction label="Dashboard" icon={<Dashboard fontSize="small" />} value="/" />
            <BottomNavigationAction label="Trips" icon={<Calendar fontSize="small" />} value="/trips" />
            <BottomNavigationAction label="Users" icon={<Group fontSize="small" />} value="/users" />
            <BottomNavigationAction label="Photos" icon={<Photo fontSize="small" />} value="/photos" />
            <BottomNavigationAction label="Contacts" icon={<Phone fontSize="small" />} value="/contacts" />
          </BottomNavigation>
        ) : userType === 'guide' ? (
          <BottomNavigation showLabels value={value} onChange={navigate}>
            <BottomNavigationAction label="Trips" icon={<Calendar fontSize="small" />} value="/" />
            <BottomNavigationAction label="Contacts" icon={<Phone fontSize="small" />} value="/contacts" />
            <BottomNavigationAction label="Photos" icon={<Photo fontSize="small" />} value="/photos" />
            <BottomNavigationAction label="Expenses" icon={<Receipt fontSize="small" />} value="/expenses" />
          </BottomNavigation>
        ) : (
          <BottomNavigation showLabels value={value} onChange={navigate}>
            <BottomNavigationAction label="Home" icon={<Home fontSize="small" />} value="/" />
            <BottomNavigationAction label="Trips" icon={<Calendar fontSize="small" />} value="/trips" />
            <BottomNavigationAction label="Referrals" icon={<Store fontSize="small" />} value="/referrals" />
            <BottomNavigationAction label="Photos" icon={<Photo fontSize="small" />} value="/photos" />
          </BottomNavigation>
        )
      ) : null}
    </Container>
  );
});

export default Navigation;
