import axios from 'axios';

const server = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: status => status < 500,
  withCredentials: true,
});

export const addTokenFetch = getAccessTokenSilently => {
  server.interceptors.request.use(async config => {
    const token = await getAccessTokenSilently();

    config.headers.authorization = `Bearer ${token}`;
    return config;
  });
};

export default server;
