import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useDisplayErrors from '../hooks/useDisplayErrors';
import useDisplayMessages from '../hooks/useDisplayMessages';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import Close from '@mui/icons-material/Close';

const Status = () => {
  const errors = useDisplayErrors();
  const messages = useDisplayMessages();
  const [active, setActive] = useState({
    severity: 'error',
    reducer: '',
    message: '',
    close: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (errors?.length > 0) {
      setActive(errors[0]);
    } else if (messages?.length > 0) {
      setActive(messages[0]);
    } else {
      setActive({
        severity: 'error',
        reducer: '',
        message: '',
        close: null,
      });
    }
  }, [errors, messages]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={active?.reducer !== ''}
      onClose={() => dispatch(active.close())}
      autoHideDuration={active?.severity === 'success' ? 2000 : null}
      key={active?.message ?? 'default'}
      sx={{ transform: 'translateY(60px)' }}
      TransitionComponent={props => <Slide {...props} direction="left" />}
    >
      <Alert
        key={active?.reducer + active?.severity}
        severity={active?.severity}
        variant="filled"
        onClose={() => dispatch(active.close())}
        action={
          <Stack>
            <IconButton color="inherit" onClick={() => dispatch(active.close())}>
              <Close />
            </IconButton>
          </Stack>
        }
      >
        <AlertTitle sx={{ textTransform: 'capitalize' }}>
          {active?.severity === 'success' ? 'Success' : 'Error'}
        </AlertTitle>
        {active.message}
      </Alert>
    </Snackbar>
  );
};

export default Status;
