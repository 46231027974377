import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// font-family: Raleway, sans-serif;
// font-family: Roboto Condensed, sans-serif;

// Changes must be made in ../components/Calendar/Calendar.scss and ../index.css

// Hunt Palette
// 323C1A Dark Green
// C57200 Orange
// 998F76 Tan

// Fish Palette
// 1D3257 Dark Blue
// 618D48 Green
// 5589AC Light Blue

const theme = process.env.REACT_APP_THEME ?? 'hunt';

const palette = {
  hunt: {
    primary: { main: '#998F76' },
    secondary: { main: '#C57200' },
    tertiary: { light: '#424F24', main: '#323C1A', dark: '#202711', contrastText: '#fff' },
    error: { main: '#A61111' },
    warning: { main: '#FF7000' },
    info: { main: '#00AAFF' },
    success: { main: '#099100' },
    header: { main: '#323C1A' },
    background: { default: '#DDD6C5' },
  },
  fish: {
    primary: { main: '#5589AC' },
    secondary: { main: '#618D48' },
    tertiary: { light: '#273D64', main: '#1D3257', dark: '#152542', contrastText: '#fff' },
    error: { main: '#A61111' },
    warning: { main: '#FF7000' },
    info: { main: '#00AAFF' },
    success: { main: '#099100' },
    header: { main: '#1D3257' },
    background: { default: '#E0EFF9' },
  },
};

const constants = {
  typography: {
    h1: {
      fontFamily: 'Raleway, sans-serif',
      color: palette[theme].tertiary.main,
    },
    h2: {
      fontFamily: 'Raleway, sans-serif',
      color: palette[theme].tertiary.main,
    },
    h3: {
      fontFamily: 'Raleway, sans-serif',
      color: palette[theme].tertiary.main,
    },
    h4: {
      fontFamily: 'Raleway, sans-serif',
      color: palette[theme].tertiary.main,
    },
    h5: {
      fontFamily: 'Raleway, sans-serif',
      color: palette[theme].tertiary.main,
    },
    h6: {
      fontFamily: 'Raleway, sans-serif',
      color: palette[theme].tertiary.main,
    },
    fontFamily: 'Roboto Condensed, sans-serif',
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        message: {
          width: '100%',
        },
        action: {
          alignItems: 'center',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.hover === 'true' && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        }),
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          minHeight: 65,
          backgroundColor: palette[theme].tertiary.main,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          padding: 0,
          minWidth: 60,
          minHeight: 65,
          color: palette[theme].primary.main,
          '&.Mui-selected': {
            color: palette[theme].secondary.main,
          },
          '& .MuiSvgIcon-root': {
            width: '1.5rem',
            height: '1.5rem',
          },
        },
        label: {
          fontSize: '0.8rem',
          '&.Mui-selected': {
            fontSize: '0.9rem',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        variant: 'contained',
        color: 'secondary',
      },
    },
    MuiCard: {
      defaultProps: {
        sx: {
          boxShadow: 1,
        },
      },
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.hover === 'true' && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          color: 'tertiary.main',
        },
      },
      styleOverrides: {
        action: ({ ownerState, _ }) => ({
          ...(ownerState.center_action === 'true' && {
            alignSelf: 'center',
          }),
        }),
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          backgroundColor: palette[theme].primary.main,
        },

        '*::-webkit-scrollbar-thumb': {
          backgroundColor: palette[theme].secondary.main,
          borderRadius: '12px',
        },

        'input:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 50px ${palette[theme].background.default} inset`,
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: 'fullWidth',
      },
    },
    MuiGrid: {
      defaultProps: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      styleOverrides: {
        root: {
          listStyleType: 'none',
          padding: 0,
        },
      },
    },
    MuiImageList: {
      styleOverrides: {
        root: {
          overflowY: 'visible',
        },
      },
    },
    MuiImageListItem: {
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.hover && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        }),
        img: {
          objectFit: 'contain',
        },
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000CC',
        },
        title: {
          color: palette[theme].primary.main,
          fontSize: '1.3rem',
          fontWeight: 500,
          textAlign: 'right',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          padding: '0.25rem 2rem 0.25rem 2rem',
          borderRadius: 25,
          margin: '0',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          zIndex: 1200,
        },
      },
    },
    MuiModal: {
      defaultProps: {
        closeAfterTransition: true,
        sx: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '96vw',
          margin: 'auto',
        },
      },
    },
    MuiStack: {
      defaultProps: {
        alignItems: 'center',
        justifyContent: 'center',
        spacing: 1,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'standard',
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.link === 'true' && {
            '&:hover': {
              cursor: 'pointer',
              color: palette[theme].secondary.main,
            },
          }),
        }),
      },
    },
  },
};

const Theme = responsiveFontSizes(
  createTheme({
    ...constants,
    palette: {
      mode: 'light',
      ...palette[theme],
    },
  })
);

export default Theme;
