import * as actionTypes from './actionTypes';

export const clientInit = () => ({ type: actionTypes.CLIENT.INIT });
export const clientError = err => ({ type: actionTypes.CLIENT.ERROR, error: err });

export const clientGetProfile = () => ({ type: actionTypes.CLIENT.GET_PROFILE });
export const clientGetProfileSuccess = data => ({ type: actionTypes.CLIENT.GET_PROFILE_SUCCESS, data });

export const clientEditProfile = data => ({ type: actionTypes.CLIENT.EDIT_PROFILE, data });
export const clientEditProfileSuccess = data => ({ type: actionTypes.CLIENT.EDIT_PROFILE_SUCCESS, data });

export const clientRemoveProfile = data => ({ type: actionTypes.CLIENT.REMOVE_PROFILE, data });
export const clientRemoveProfileSuccess = () => ({ type: actionTypes.CLIENT.REMOVE_PROFILE_SUCCESS });

export const clientClearErr = () => ({ type: actionTypes.CLIENT.CLEAR_ERR });
export const clientClearMsg = () => ({ type: actionTypes.CLIENT.CLEAR_MSG });
