import { array, boolean, date, number, object, ref, string } from 'yup';
import { countries, states } from './constants';

export const adminAndGuideSchema = object({
  firstName: string()
    .trim()
    .required('Required')
    .min(2)
    .matches(/^[a-zA-Z-]*$/, 'Letters Only'),
  lastName: string()
    .trim()
    .required('Required')
    .min(2)
    .matches(/^[a-zA-Z-]*$/, 'Letters Only'),
  street: string().trim().required('Required'),
  suite: string().trim(),
  city: string().trim().required('Required'),
  postal: string()
    .trim()
    .required('Required')
    .matches(
      /^(?:(\d{5}(?:[- ]?\d{4})?)|((?!.*[DFIOQU])[A-VXY][0-9][A-Z](-|\s)?[0-9][A-Z][0-9]))$/i,
      'ie: 16365 or T0L 0K0'
    ),
  state: string()
    .required('Required')
    .oneOf(states.flatMap(state => state.value)),
  country: string()
    .required('Required')
    .oneOf(countries.flatMap(country => country.value)),
  phone: string()
    .required('Required')
    .matches(/^\d{3}(-| |)\d{3}(-| |)\d{4}$/, 'ie: xxx-xxx-xxxx'),
  email: string().trim().required('Required').email(),
  drivers: string().trim().min(6).max(17).required('Required'),
});

export const appInfoSchema = object({
  bio: string().trim().required('Required'),
  name: string().trim().required('Required'),
  phone: string()
    .trim()
    .required('Required')
    .matches(/^\d{3}(-| |)\d{3}(-| |)\d{4}$/, 'ie: xxx-xxx-xxxx'),
  email: string().trim().required('Required').email(),
  website: string().trim().url('ie: https://example.com'),
  sendAlertsAt: number().required('Required').min(14).max(180),
  street: string().trim().required('Required'),
  suite: string().trim(),
  city: string().trim().required('Required'),
  postal: string()
    .trim()
    .required('Required')
    .matches(
      /^(?:(\d{5}(?:[- ]?\d{4})?)|((?!.*[DFIOQU])[A-VXY][0-9][A-Z](-|\s)?[0-9][A-Z][0-9]))$/i,
      'ie: 16365 or T0L 0K0'
    ),
  state: string()
    .required('Required')
    .oneOf(states.flatMap(state => state.value)),
  country: string()
    .required('Required')
    .oneOf(countries.flatMap(country => country.value)),
});

export const clientSchema = object({
  firstName: string()
    .trim()
    .required('Required')
    .min(2)
    .matches(/^[a-zA-Z-]*$/, 'Letters Only'),
  lastName: string()
    .trim()
    .required('Required')
    .min(2)
    .matches(/^[a-zA-Z-]*$/, 'Letters Only'),
  street: string().trim().required('Required'),
  suite: string().trim(),
  city: string().trim().required('Required'),
  postal: string()
    .trim()
    .required('Required')
    .matches(
      /^(?:(\d{5}(?:[- ]?\d{4})?)|((?!.*[DFIOQU])[A-VXY][0-9][A-Z](-|\s)?[0-9][A-Z][0-9]))$/i,
      'ie: 16365 or T0L 0K0'
    ),
  state: string()
    .required('Required')
    .oneOf(states.flatMap(state => state.value)),
  country: string()
    .required('Required')
    .oneOf(countries.flatMap(country => country.value)),
  phone: string()
    .trim()
    .required('Required')
    .matches(/^\d{3}(-| |)\d{3}(-| |)\d{4}$/, 'ie: xxx-xxx-xxxx'),
  secondaryPhone: string()
    .trim()
    .matches(/^\d{3}(-| |)\d{3}(-| |)\d{4}$/, 'ie: xxx-xxx-xxxx, Optional'),
  email: string().trim().required('Required').email(),
  emergencyContactName: string()
    .trim()
    .required('Required')
    .matches(/^[a-zA-Z-]* [a-zA-Z-]*$/, 'ie: John Doe'),
  emergencyContactPhone: string()
    .trim()
    .required('Required')
    .matches(/^\d{3}(-| |)\d{3}(-| |)\d{4}$/, 'ie: xxx-xxx-xxxx')
    .test('is-own-phone-number', 'Can not be your number', (value, context) => value !== context.parent.phone),
  physicalCondition: string().required('Required').oneOf(['fair', 'good', 'poor']),
  hasDietaryRestrictions: boolean().required('Required'),
  dietaryRestrictions: string()
    .trim()
    .when('hasDietaryRestrictions', {
      is: true,
      then: schema => schema.required('Required'),
    }),
  hasMedicalConditions: boolean().required('Required'),
  medicalConditions: string()
    .trim()
    .when('hasMedicalConditions', { is: true, then: schema => schema.required('Required') }),
  hasMedicalAllergies: boolean().required('Required'),
  medicalAllergies: string()
    .trim()
    .when('hasMedicalAllergies', { is: true, then: schema => schema.required('Required') }),
  WIN: string().trim().min(10).max(10),
  birthday: date().required('Required').max(new Date()),
  height: string().trim().required('Required'),
  weight: string().trim().required('Required'),
  drivers: string().trim().min(6).max(17).required('Required'),
});

export const contactSchema = object({
  name: string()
    .trim()
    .matches(/^[a-zA-Z-]* [a-zA-Z-]*$/, 'ie: first last')
    .required('Required'),
  address: string().trim().required('Required'),
  phone: string()
    .trim()
    .matches(/^\d{3}(-|\s|)\d{3}(-|\s|)\d{4}$/, 'ie: xxx-xxx-xxxx')
    .required('Required'),
  secondaryPhone: string()
    .trim()
    .matches(/^\d{3}(-|\s|)\d{3}(-|\s|)\d{4}$/, 'ie: xxx-xxx-xxxx'),
  note: string().trim(),
});

export const expenseSchema = object({
  description: string().trim(),
  type: string().required('Required').oneOf(['fuel', 'food', 'gifts', 'supplies', 'misc', 'day rate', 'kill']),
  amount: number().required('Required'),
  photo: boolean().oneOf([true]),
  trip: string().required('Required'),
});

export const eventSchema = object({
  startDate: date()
    .required('Required')
    .when('edit', {
      is: false,
      then: schema => schema.min(new Date(Date.now() - 43_200_000), 'Must Be A Future Date'),
    }),
  endDate: date().required('Required').min(ref('startDate'), 'Must be on or after Start Date'),
  title: string().trim().required('Required'),
  body: string().trim().max(300, '300 characters or less'),
});

export const paymentSchema = object({
  date: date().required('Required'),
  amount: number().required().min(1).max(ref('remainder'), 'This is an over payment'),
  comment: string().trim(),
  remainder: number(),
});

export const policySchema = object({
  customCancellation: boolean(),
  cancellation: string()
    .trim()
    .when('customCancellation', { is: true, then: schema => schema.min(10) }),
  customPayment: boolean(),
  payment: string()
    .trim()
    .when('customPayment', { is: true, then: schema => schema.min(10) }),
  customWound: boolean(),
  wound: string()
    .trim()
    .when('customWound', { is: true, then: schema => schema.min(10) }),
});

export const postSchema = object({
  title: string().trim().required('Required'),
  body: string().trim().min(10).required('Required'),
});

// Something to do with circular dependencies (phone & email) that requires the use of shape()
export const referralSchema = object().shape(
  {
    type: string().trim().required('Required').oneOf(['taxidermist', 'outfitter', 'retail', 'service']),
    name: string().trim().required('Required'),
    email: string()
      .trim()
      .email()
      .when('phone', { is: phone => !phone, then: schema => schema.required('Phone or Email Required') }),
    location: string().trim().required('Required'),
    phone: string()
      .trim()
      .matches(/^\d{3}(-| |)\d{3}(-| |)\d{4}$/, 'ie: xxx-xxx-xxxx')
      .when('email', { is: email => !email, then: schema => schema.required('Phone or Email Required') }),
    website: string().trim().url('ie: https://example.com'),
    note: string().trim(),
  },
  ['email', 'phone']
);

export const travelPlanSchema = object({
  offered: boolean().required('Required'),
  instruction: string()
    .trim()
    .when('offered', { is: true, then: schema => schema.required('Required') }),
  accepted: boolean().required('Required'),
  details: string().trim(),
});

export const tripSchema = object({
  edit: boolean().required('Required'),
  newClient: boolean().required('Required'),
  firstName: string()
    .trim()
    .when('newClient', { is: true, then: schema => schema.required('Required') }),
  lastName: string()
    .trim()
    .when('newClient', { is: true, then: schema => schema.required('Required') }),
  email: string()
    .email()
    .trim()
    .when('newClient', { is: true, then: schema => schema.required('Required') }),
  client: string().when('newClient', { is: false, then: schema => schema.required('Required') }),
  guide: string().required('Required'),
  type: string().required('Required'),
  travelInDate: date()
    .required('Required')
    .max(ref('startDate'), 'Latest is Start Date set')
    .when('edit', {
      is: false,
      then: schema => schema.min(new Date(Date.now() - 43_200_000), 'Must Be A Future Date'),
    }),
  travelOutDate: date().required('Required').min(ref('endDate'), 'Earliest is End Date set'),
  travelOffer: boolean().required('Required'),
  travelInstruction: string()
    .trim()
    .when('travelOffer', { is: true, then: schema => schema.required('Required') }),
  startDate: date()
    .required('Required')
    .when('edit', {
      is: false,
      then: schema => schema.min(new Date(Date.now() - 43_200_000), 'Must Be A Future Date'),
    }),
  endDate: date().required('Required').min(ref('startDate'), 'Must be on or after Start Date'),
  value: number().required('Required'),
  amountPaid: number().max(ref('totalValue'), 'Amount Paid cannot exceed Total Value'),
});

export const tripTypeSchema = object({
  name: string().trim().required('Required'),
  info: string().trim().required('Required'),
  accommodationsProvided: boolean().required('Required'),
  accommodationsInfo: string()
    .trim()
    .when('accommodationsProvided', { is: true, then: schema => schema.required('Required') }),
  accommodationsAlbum: string().trim(),
  location: string().trim().required('Required'),
  coordinates: string()
    .trim()
    .required('Required')
    .matches(/^-?\d{1,3}.\d{5,},-?\d{1,3}.\d{5,}$/, 'ie: 54.123456,-114.654321 no spaces'),
  invoiceItems: array().of(
    object({
      name: string().trim().required('Required'),
      price: number().required('Required'),
      unit: string().trim(),
    })
  ),
  enableGST: boolean(),
  gstRate: number().when('enableGST', { is: true, then: schema => schema.required('Required') }),
  gstName: string()
    .trim()
    .oneOf(['GST', 'HST', 'State Tax'])
    .when('enableGST', { is: true, then: schema => schema.required('Required') }),
  enablePST: boolean(),
  pstRate: number().when('enablePST', { is: true, then: schema => schema.required('Required') }),
  pstName: string()
    .trim()
    .oneOf(['PST', 'County Tax'])
    .when('enablePST', { is: true, then: schema => schema.required('Required') }),
  weatherLink: string().trim().url('Must include protocol http:// or https://'),
  gear: array().of(
    object({
      item: string().trim().required('Required').min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters'),
      description: string().trim().max(100, 'Maximum 100 characters'),
    })
  ),
  directions: array().of(
    object({
      step: number(),
      text: string()
        .trim()
        .required('Required')
        .min(10, 'Minimum 10 characters')
        .max(150, 'Maximum 150 characters, add a new step'),
    })
  ),
});

export const userSchema = object({
  type: string().required('Required').oneOf(['admin', 'guide', 'client']),
  firstName: string()
    .trim()
    .required('Required')
    .matches(/^[a-zA-Z-]*$/, 'Only letters and hyphens allowed'),
  lastName: string()
    .trim()
    .required('Required')
    .matches(/^[a-zA-Z-]*$/, 'Only letters and hyphens allowed'),
  email: string().trim().required('Required').email('Must be valid email'),
});
