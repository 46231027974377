import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Cancel from '@mui/icons-material/Cancel';
import Check from '@mui/icons-material/CheckCircle';

const Expense = props => {
  const { _id, type, amount, admin, guide, date, description, trip, reimbursed } = props;
  const userType = useSelector(state => state.app.userType);

  return (
    <Card key={_id}>
      <CardHeader
        title={type + ' - $' + amount}
        titleTypographyProps={{ sx: { textTransform: 'capitalize' } }}
        subheader={
          (userType === 'admin' ? (admin ? admin?.user?.fullName : guide?.user?.fullName) : 'Submitted') +
          ' on ' +
          dayjs(date).format('MMM D, YYYY')
        }
      />
      <CardContent sx={{ px: 2 }}>
        <Stack alignItems="start">
          <Grid container sx={{ maxWidth: 500 }} alignItems="start">
            <Grid item xs={4}>
              <Typography>Client</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color={trip?.client?.user ? 'inherit' : 'error'}>
                {trip?.client?.user?.fullName ?? 'Client Has Been Removed'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Trip</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{trip?.type?.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Trip Date</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{dayjs(trip?.startDate).format('MMM D, YYYY')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Description</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography>Reimbursed</Typography>
                </Grid>
                <Grid item xs={8} sx={{ pt: 0.5 }}>
                  {reimbursed ? <Check color="success" /> : <Cancel color="error" />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Expense;
