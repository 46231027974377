import { combineReducers } from 'redux';

import admin from './admin.reducer';
import app from './app.reducer';
import client from './client.reducer';
import guide from './guide.reducer';
import trip from './trip.reducer';

const rootReducer = combineReducers({
  admin,
  app,
  client,
  guide,
  trip,
});

export default rootReducer;
