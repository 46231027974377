import * as actions from '../actions';
import { put } from 'redux-saga/effects';
import server from '../../assets/axios';

export function* adminGetProfileSaga() {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin');

    if (status === 200) {
      yield put(actions.adminGetProfileSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminEditProfileSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.put('/admin', action.data);

    if (status === 200) {
      yield put(actions.adminEditProfileSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminUploadLogoSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.post('/admin/logo', action.data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (status === 200) {
      yield put(actions.adminUploadLogoSuccess(data));
    } else {
      yield put(actions.adminError(data?.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminGetAllUsersSaga() {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin/users');

    if (status === 200) {
      yield put(actions.adminGetUsersSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminGetUserSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin/user', { params: action.data });

    if (status === 200) {
      yield put(actions.adminGetUserSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminCreateUserSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.post('/admin/user', action.data);

    if (status === 200) {
      yield put(actions.adminCreateUserSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminEditUserSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.put('/admin/user', action.data);

    if (status === 200) {
      yield put(actions.adminEditUserSuccess(action.data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminRemoveUserSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.delete('/admin/user', { data: { ...action.data } });

    if (status === 200) {
      yield put(actions.adminRemoveUserSuccess(action.data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminSearchUsersSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin/search', { params: action.data });

    if (status === 200) {
      yield put(actions.adminUserSearchSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminClientWinSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.put('/admin/client-win', action.data);

    if (status === 200) {
      yield put(actions.adminClientWinSuccess(action.data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminGetTripTypeSaga() {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin/types');

    if (status === 200) {
      yield put(actions.adminGetTripTypeSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminCreateTripTypeSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.post('/admin/types', action.data);

    if (status === 200) {
      yield put(actions.adminCreateTripTypeSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminEditTripTypeSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.put('/admin/types', action.data);

    if (status === 200) {
      yield put(actions.adminEditTripTypeSuccess(action.data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminRemoveTripTypeSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.delete('/admin/types', { data: action.data });

    if (status === 200) {
      yield put(actions.adminRemoveTripTypeSuccess(action.data.type));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminGetReferralsSaga() {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin/referral');

    if (status === 200) {
      yield put(actions.adminGetReferralsSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminCreateReferralSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.post('/admin/referral', action.data);

    if (status === 200) {
      yield put(actions.adminCreateReferralSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminEditReferralSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.put('/admin/referral', action.data);

    if (status === 200) {
      yield put(actions.adminEditReferralSuccess(action.data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminRemoveReferralSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.delete('/admin/referral', { data: action.data });

    if (status === 200) {
      yield put(actions.adminRemoveReferralSuccess(action.data.referral));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminGetPostsSaga() {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin/posts');

    if (status === 200) {
      yield put(actions.adminGetPostsSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminCreatePostSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.post('/admin/posts', action.data);

    if (status === 200) {
      yield put(actions.adminCreatePostSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminEditPostSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.put('/admin/posts', action.data);

    if (status === 200) {
      yield put(actions.adminEditPostSuccess(action.data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminRemovePostSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.delete('/admin/posts', { data: action.data });

    if (status === 200) {
      yield put(actions.adminRemovePostSuccess(action.data.post));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminUpdatePoliciesSaga(action) {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.post('/admin/policies', action.data);

    if (status === 200) {
      yield put(actions.adminUpdatePoliciesSuccess(action.data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminGetExampleContractSaga() {
  yield put(actions.adminInit());
  try {
    const { status, data } = yield server.get('/admin/example', {
      responseType: 'arraybuffer',
    });

    if (status === 200) {
      yield put(actions.adminGetExampleContractSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}

export function* adminGetInvoicesSaga() {
  yield put(actions.adminInit());
  try {
      const { status, data } = yield server.get('/admin/invoices');

    if (status === 200) {
      yield put(actions.adminGetInvociesSuccess(data));
    } else {
      yield put(actions.adminError(data.error));
    }
  } catch (err) {
    yield put(actions.adminError(err.message));
  }
}
