import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  message: null,
  error: null,
  profile: {
    auth0: null,
    name: {
      first: null,
      last: null,
    },
    email: null,
    phone: null,
    drivers: null,
    address: {
      street: null,
      suite: null,
      city: null,
      postal: null,
      state: null,
      country: null,
    },
  },
  admins: [],
  clients: [],
  guides: [],
  types: [],
  invoices: [],
  invoicesFetched: false,
  selected: null,
  exampleContract: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN.INIT:
      return { ...state, loading: true, error: null };
    case actionTypes.APP.INITIALIZE_SUCCESS:
      return { ...state, types: action.data.types ?? [] };
    case actionTypes.ADMIN.GET_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.data };
    case actionTypes.ADMIN.EDIT_PROFILE_SUCCESS:
      return { ...state, loading: false, message: 'Profile Updated!', profile: action.data };
    case actionTypes.ADMIN.UPLOAD_LOGO_SUCCESS:
      return { ...state, loading: false, message: 'Logo Updated!' };
    case actionTypes.ADMIN.GET_ALL_USERS_SUCCESS:
      return { ...state, loading: false, ...action.data };
    case actionTypes.ADMIN.GET_USER_SUCCESS:
      return { ...state, loading: false, selected: { ...action.data.user, trips: action.data.trips } };
    case actionTypes.ADMIN.CREATE_USER_SUCCESS: {
      switch (action.data.userType) {
        case 'admin':
          return { ...state, loading: false, message: 'Admin Created!', admins: [...state.admins, action.data.user] };
        case 'guide':
          return { ...state, loading: false, message: 'Guide Created!', guides: [...state.guides, action.data.user] };
        default:
          return {
            ...state,
            loading: false,
            message: 'Client Created!',
            clients: [...state.clients, action.data.user],
          };
      }
    }
    case actionTypes.ADMIN.EDIT_USER_SUCCESS: {
      switch (action.data.userType) {
        case 'admin':
          return {
            ...state,
            loading: false,
            message: 'Admin Updated!',
            admins: state.admins.map(user =>
              action.data.user === user.user._id
                ? {
                    ...user,
                    user: {
                      ...user.user,
                      email: action.data.email,
                    },
                  }
                : user
            ),
            selected: {
              ...state.selected,
              email: action.data.email,
            },
          };
        case 'guide':
          return {
            ...state,
            loading: false,
            message: 'Guide Updated!',
            guides: state.guides.map(user =>
              action.data.user === user.user._id
                ? {
                    ...user,
                    user: {
                      ...user.user,
                      email: action.data.email,
                    },
                  }
                : user
            ),
            selected: {
              ...state.selected,
              email: action.data.email,
            },
          };
        default:
          return {
            ...state,
            loading: false,
            message: 'Client Updated!',
            clients: state.clients.map(user =>
              action.data.user === user.user._id
                ? {
                    ...user,
                    user: {
                      ...user.user,
                      email: action.data.email,
                    },
                  }
                : user
            ),
            selected: {
              ...state.selected,
              email: action.data.email,
            },
          };
      }
    }
    case actionTypes.ADMIN.REMOVE_USER_SUCCESS: {
      switch (action.data.userType) {
        case 'admin':
          return {
            ...state,
            loading: false,
            message: 'Admin Removed!',
            admins: state.admins.filter(user => action.data.user !== user.user._id),
            selected: null,
          };
        case 'guide':
          return {
            ...state,
            loading: false,
            message: 'Guide Removed!',
            guides: state.guides.filter(user => action.data.user !== user.user._id),
            selected: null,
          };
        default:
          return {
            ...state,
            loading: false,
            message: 'Client Removed!',
            clients: state.clients.filter(user => action.data.user !== user.user._id),
            selected: null,
          };
      }
    }
    case actionTypes.ADMIN.SEARCH_USERS_SUCCESS: {
      switch (action.data.userType) {
        case 'admins':
          return {
            ...state,
            loading: false,
            admins: action.data.users,
          };
        case 'guides':
          return {
            ...state,
            loading: false,
            guides: action.data.users,
          };
        default:
          return {
            ...state,
            loading: false,
            clients: action.data.users,
          };
      }
    }
    case actionTypes.ADMIN.CLEAR_SELECTED_USER:
      return { ...state, selected: null };
    case actionTypes.ADMIN.CLIENT_WIN_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Client WIN Updated!',
        clients: state.clients.map(user =>
          action.data.user === user.user._id
            ? {
                ...user,
                user: {
                  ...user.user,
                  WIN: action.data.WIN,
                },
              }
            : user
        ),
        selected: {
          ...state.selected,
          WIN: action.data.WIN,
        },
      };
    case actionTypes.ADMIN.GET_TRIP_TYPE_SUCCESS:
      return { ...state, loading: false, types: action.data };
    case actionTypes.ADMIN.CREATE_TRIP_TYPE_SUCCESS:
      return { ...state, loading: false, message: 'Trip Type Created!', types: [...state.types, action.data] };
    case actionTypes.ADMIN.EDIT_TRIP_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Type Updated!',
        types: state.types.map(types => (types._id !== action.data._id ? types : action.data)),
      };
    case actionTypes.ADMIN.REMOVE_TRIP_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Type Removed!',
        types: state.types.filter(types => types._id !== action.data),
      };
    case actionTypes.ADMIN.GET_REFERRALS_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.ADMIN.ADD_REFERRAL_SUCCESS:
      return { ...state, loading: false, message: 'Referral Added!' };
    case actionTypes.ADMIN.EDIT_REFERRAL_SUCCESS:
      return { ...state, loading: false, message: 'Referral Updated!' };
    case actionTypes.ADMIN.REMOVE_REFERRAL_SUCCESS:
      return { ...state, loading: false, message: 'Referral Removed!' };
    case actionTypes.ADMIN.GET_POSTS_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.ADMIN.CREATE_POST_SUCCESS:
      return { ...state, loading: false, message: 'Post Created!' };
    case actionTypes.ADMIN.EDIT_POST_SUCCESS:
      return { ...state, loading: false, message: 'Post Updated!' };
    case actionTypes.ADMIN.REMOVE_POST_SUCCESS:
      return { ...state, loading: false, message: 'Post Removed!' };
    case actionTypes.ADMIN.EXAMPLE_CONTRACT_SUCCESS:
      return { ...state, loading: false, exampleContract: action.data };
    case actionTypes.ADMIN.GET_INVOICES_SUCCESS:
      return { ...state, loading: false, invoices: action.data, invoicesFetched: true }
    case actionTypes.ADMIN.ERROR:
      return { ...state, loading: false, error: action.error };
    case actionTypes.ADMIN.CLEAR_ERR:
      return { ...state, error: null };
    case actionTypes.ADMIN.CLEAR_MSG:
      return { ...state, message: null };
    case actionTypes.APP.REMOVE_ALBUM_SUCCESS:
      return {
        ...state,
        types: state.types.map(type =>
          type.accommodations.album === action.data
            ? { ...type, accommodations: { ...type.accommodations, album: undefined } }
            : type
        ),
      };
    case actionTypes.TRIP.CREATE_SUCCESS:
      return action.data.newClient ? { ...state, clients: [...state.clients, action.data.client] } : state;
    default:
      return state;
  }
};

export default reducer;
