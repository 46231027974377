import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  message: null,
  error: null,
  profile: {
    auth0: null,
    name: {
      first: null,
      last: null,
    },
    email: null,
    phone: null,
    drivers: null,
    address: {
      street: null,
      suite: null,
      city: null,
      postal: null,
      state: null,
      country: null,
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GUIDE.INIT:
      return { ...state, loading: true, error: null };
    case actionTypes.GUIDE.GET_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.data };
    case actionTypes.GUIDE.EDIT_PROFILE_SUCCESS:
      return { ...state, loading: false, message: 'Profile Updated!', profile: { ...state.profile, ...action.data } };
    case actionTypes.GUIDE.ERROR:
      return { ...state, loading: false, error: action.error };
    case actionTypes.GUIDE.CLEAR_ERR:
      return { ...state, error: null };
    case actionTypes.GUIDE.CLEAR_MSG:
      return { ...state, message: null };
    default:
      return state;
  }
};

export default reducer;
