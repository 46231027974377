import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import Theme from './index';

const Provider = ({ children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Provider;
