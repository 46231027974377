import * as actions from '../actions';
import { put } from 'redux-saga/effects';
import server from '../../assets/axios';

export function* clientGetProfileSaga() {
  yield put(actions.clientInit());
  try {
    const { status, data } = yield server.get('/client');

    if (status === 200) {
      yield put(actions.clientGetProfileSuccess(data));
    } else {
      yield put(actions.clientError(data.error));
    }
  } catch (err) {
    yield put(actions.clientError(err.message));
  }
}

export function* clientEditProfileSaga(action) {
  yield put(actions.clientInit());
  try {
    const { status, data } = yield server.put('/client', action.data);

    if (status === 200) {
      yield put(actions.clientEditProfileSuccess(data));
    } else {
      yield put(actions.clientError(data.error));
    }
  } catch (err) {
    yield put(actions.clientError(err.message));
  }
}

export function* clientRemoveProfileSaga() {
  yield put(actions.clientInit());
  try {
    const { status, data } = yield server.delete('/client');

    if (status === 200) {
      yield put(actions.clientRemoveProfileSuccess());
    } else {
      yield put(actions.clientError(data.error));
    }
  } catch (err) {
    yield put(actions.clientError(err.message));
  }
}
