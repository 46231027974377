import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { appEditEvent, appRemoveEvent } from '../../store/actions';

import { SwitchTransition } from 'react-transition-group';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Close from '@mui/icons-material/Close';

import DateRange from '../../components/Interface/DateRange';

import { eventSchema } from '../../assets/validationSchemas';
import { Typography } from '@mui/material';

const EditCustomEvent = props => {
  const { eventId, close } = props;
  const { loading, events } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const [event, setEvent] = useState(null);
  const [remove, toggleRemove] = useState(false);

  useEffect(() => {
    setEvent(events.find(event => event._id === eventId) ?? null);
  }, [events, eventId]);

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      event: event?._id ?? '',
      startDate: event?.startDate ?? null,
      endDate: event?.endDate ?? null,
      title: event?.title ?? '',
      body: event?.body ?? '',
    },
    enableReinitialize: true,
    validationSchema: eventSchema,
    onSubmit: async values => {
      dispatch(appEditEvent(values));
    },
  });

  useEffect(() => {
    resetForm();
  }, [resetForm, events]);

  const createInput = (formikKey, label, multiline = false) => (
    <TextField
      key={formikKey}
      id={formikKey}
      name={formikKey}
      label={label}
      value={formik.values[formikKey]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      multiline={multiline}
      error={formik.touched[formikKey] && Boolean(formik.errors[formikKey])}
      helperText={formik.touched[formikKey] && formik.errors[formikKey]}
    />
  );

  return (
    <Modal open={event !== null} onClose={close}>
      <Slide in={event !== null}>
        <Card>
          <CardHeader
            title={event?.title ?? 'Your Event'}
            subheader="This event is only visible to you"
            action={
              <IconButton onClick={close}>
                <Close color="error" />
              </IconButton>
            }
          />
          <SwitchTransition>
            <Collapse key={remove}>
              {!remove ? (
                <CardContent>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={12}>
                        <DateRange
                          event
                          emit={data => {
                            formik.setFieldTouched('startDate');
                            formik.setFieldTouched('endDate');
                            formik.setFieldValue('startDate', data?.start ?? null, true);
                            formik.setFieldValue('endDate', data?.end ?? null, true);
                          }}
                          start={formik.values.startDate}
                          end={formik.values.endDate}
                          error={
                            (formik.touched.startDate && formik.errors.startDate) ||
                            (formik.touched.endDate && formik.errors.endDate)
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <DatePicker
                          disablePast
                          emit={data => {
                            formik.setFieldTouched('startDate');
                            formik.setFieldValue('startDate', data ?? null, true);
                          }}
                          label="Start Date"
                          value={formik.values.startDate}
                          error={formik.touched.startDate && formik.errors.startDate}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          disablePast
                          minimum={formik.values.startDate}
                          emit={data => {
                            formik.setFieldTouched('endDate');
                            formik.setFieldValue('endDate', data ?? null, true);
                          }}
                          label="End Date"
                          value={formik.values.endDate}
                          error={formik.touched.endDate && formik.errors.endDate}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        {createInput('title', 'Title', false)}
                      </Grid>
                      <Grid item xs={12}>
                        {createInput('body', 'Description', true)}
                      </Grid>
                      <Grid item xs={12}>
                        <SwitchTransition>
                          <Collapse key={formik.dirty}>
                            {formik.dirty ? (
                              <Stack pt={2}>
                                <SwitchTransition>
                                  <Fade key={loading ? 'loader' : 'button'}>
                                    {loading ? (
                                      <CircularProgress />
                                    ) : (
                                      <Stack direction="row" spacing={4}>
                                        <Button onClick={resetForm} disabled={!formik.dirty}>
                                          Undo
                                        </Button>
                                        <Button onClick={formik.handleSubmit} disabled={!formik.dirty} color="tertiary">
                                          Save
                                        </Button>
                                      </Stack>
                                    )}
                                  </Fade>
                                </SwitchTransition>
                              </Stack>
                            ) : (
                              <Stack pt={2}>
                                <Button color="error" onClick={() => toggleRemove(true)}>
                                  Delete Event
                                </Button>
                              </Stack>
                            )}
                          </Collapse>
                        </SwitchTransition>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              ) : (
                <CardContent>
                  <Stack spacing={3}>
                    <Typography variant="h6">Are you sure you want to delete this event?</Typography>
                    <SwitchTransition>
                      <Fade key={loading ? 'loader' : 'button'}>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Stack direction="row" spacing={4}>
                            <Button onClick={() => toggleRemove(false)}>Cancel</Button>
                            <Button onClick={() => dispatch(appRemoveEvent(eventId))} color="error">
                              Yes, Delete
                            </Button>
                          </Stack>
                        )}
                      </Fade>
                    </SwitchTransition>
                  </Stack>
                </CardContent>
              )}
            </Collapse>
          </SwitchTransition>
        </Card>
      </Slide>
    </Modal>
  );
};

export default EditCustomEvent;
