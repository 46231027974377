import * as actionTypes from './actionTypes';

export const tripInit = () => ({ type: actionTypes.TRIP.INIT });
export const tripError = err => ({ type: actionTypes.TRIP.ERROR, error: err });

export const tripCreate = data => ({ type: actionTypes.TRIP.CREATE, data });
export const tripCreateSuccess = data => ({ type: actionTypes.TRIP.CREATE_SUCCESS, data });

export const tripEdit = data => ({ type: actionTypes.TRIP.EDIT, data });
export const tripEditSuccess = data => ({ type: actionTypes.TRIP.EDIT_SUCCESS, data });

export const tripRemove = data => ({ type: actionTypes.TRIP.REMOVE, data });
export const tripRemoveSuccess = data => ({ type: actionTypes.TRIP.REMOVE_SUCCESS, data });

export const tripResend = data => ({ type: actionTypes.TRIP.RESEND, data });
export const tripResendSuccess = () => ({ type: actionTypes.TRIP.RESEND_SUCCESS });

export const tripFetchAdmin = () => ({ type: actionTypes.TRIP.FETCH_ADMIN });
export const tripFetchGuide = () => ({ type: actionTypes.TRIP.FETCH_GUIDE });
export const tripFetchClient = () => ({ type: actionTypes.TRIP.FETCH_CLIENT });
export const tripFetchSuccess = data => ({ type: actionTypes.TRIP.FETCH_SUCCESS, data });

export const tripAddPayment = data => ({ type: actionTypes.TRIP.PAYMENT_ADD, data });
export const tripEditPayment = data => ({ type: actionTypes.TRIP.PAYMENT_EDIT, data });
export const tripRemovePayment = data => ({ type: actionTypes.TRIP.PAYMENT_REMOVE, data });
export const tripPaymentSuccess = data => ({ type: actionTypes.TRIP.PAYMENT_SUCCESS, data });

export const tripTravel = data => ({ type: actionTypes.TRIP.TRAVEL_PLANS, data });
export const tripTravelSuccess = data => ({ type: actionTypes.TRIP.TRAVEL_PLANS_SUCCESS, data });

export const signContract = data => ({ type: actionTypes.TRIP.SIGN_CONTRACT, data });
export const signContractSuccess = data => ({ type: actionTypes.TRIP.SIGN_CONTRACT_SUCCESS, data });

export const downloadContract = data => ({ type: actionTypes.TRIP.DOWLOAD_CONTRACT, data });
export const downloadContractSuccess = data => ({ type: actionTypes.TRIP.DOWLOAD_CONTRACT_SUCCESS, data });

export const tripClearErr = () => ({ type: actionTypes.TRIP.CLEAR_ERR });
export const tripClearMsg = () => ({ type: actionTypes.TRIP.CLEAR_MSG });
