import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SwitchTransition } from 'react-transition-group';
import { addTokenFetch } from './assets/axios';
import { appInitialize, adminGetUsers } from './store/actions';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Router from './containers/Router';

import Header from './components/Header';
import MobileNavigation from './components/Interface/MobileNavigation';
import TripNavigation from './components/Interface/TripNavigation';
import PushNotification from './containers/PushNotifications';
import Status from './containers/Status';

export default function App() {
  const { loading: reducerLoading, appInitiated, active, onboard, userType } = useSelector(state => state.app);
  const { isLoading: auth0loading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const location = useLocation();
  const dispatch = useDispatch();
  const route = useNavigate();

  useEffect(() => {
    if (getAccessTokenSilently) addTokenFetch(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(appInitialize(user['https://easy-outfitting.ca/userType']));

      if (user['https://easy-outfitting.ca/userType'] === 'admin') {
        dispatch(adminGetUsers());
      }
    }
  }, [isAuthenticated, user, dispatch]);

  useEffect(() => {
    if (userType === 'admin' && !active) {
      route('/subscription');
    } else if (userType === 'admin' && active && onboard) {
      route('/onboard');
    } else if (userType === 'guide' && !active) {
      route('/message');
    }
  }, [active, userType, onboard, route]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Stack
      sx={{ minHeight: window.innerHeight, overflowX: 'hidden' }}
      justifyContent={isAuthenticated ? 'start' : 'center'}
      alignContent="center"
    >
      <Header auth={isAuthenticated && appInitiated} userType={userType} />
      {userType === 'client' ? <PushNotification initiated={isAuthenticated && appInitiated} /> : null}
      {auth0loading || (reducerLoading && !appInitiated) ? (
        <Stack sx={{ minHeight: '98vh' }}>
          <CircularProgress />
        </Stack>
      ) : isAuthenticated && !appInitiated ? (
        <Stack sx={{ minHeight: '98vh' }}>
          <Fade in={true}>
            <Typography color="tertairy" variant="h6" sx={{ maxWidth: '35ch' }} textAlign="center">
              We are experiencing technical difficulties, please refresh or reload and try again.
            </Typography>
          </Fade>
        </Stack>
      ) : (
        <SwitchTransition component={null}>
          <Fade key={location.pathname} timeout={{ exit: 0, appear: 650, enter: 650 }}>
            <Stack spacing={0} sx={{ width: '100%', minHeight: window.innerHeight - 10, pt: 8, pb: 10, px: 2 }}>
              <Router isAuthenticated={isAuthenticated && appInitiated} userType={userType} />
            </Stack>
          </Fade>
        </SwitchTransition>
      )}
      <Status />
      <SwitchTransition>
        <Fade key={location.pathname.includes('/overview/')}>
          {location.pathname.includes('/overview/') ? (
            <TripNavigation />
          ) : (
            <MobileNavigation
              auth={
                isAuthenticated &&
                  appInitiated &&
                  // Allow clients to use the app but restrict admin and guides until subscription is resumed
                ((userType === 'admin' && active) || (userType === 'guide' && active) || userType === 'client')
              }
              userType={userType}
            />
          )}
        </Fade>
      </SwitchTransition>
    </Stack>
  );
}
