import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Dashboard from './AdminAndGuide/Dashboard';
import TripList from './Trip/List';
import PostList from './Post/List';

const Home = props => {
  const { userType, auth } = props;
  const route = useNavigate();

  return !auth ? (
    <Stack>
      <Typography variant="h4" align="center">
        Welcome to {process.env.REACT_APP_INSTANCE_NAME}
      </Typography>
      <Button onClick={() => route('/login')}>Log In</Button>
    </Stack>
  ) : userType === 'admin' && auth ? (
    <Dashboard />
  ) : userType === 'guide' && auth ? (
    <TripList />
  ) : userType === 'client' && auth ? (
    <PostList />
  ) : null;
};

export default Home;
