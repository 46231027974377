import * as actionTypes from './actionTypes';

export const appInit = () => ({ type: actionTypes.APP.INIT });
export const appError = err => ({ type: actionTypes.APP.ERROR, error: err });

export const appInitialize = data => ({ type: actionTypes.APP.INITIALIZE, data });
export const appInitializeSuccess = data => ({ type: actionTypes.APP.INITIALIZE_SUCCESS, data });

export const appUpdate = data => ({ type: actionTypes.APP.UPDATE, data });
export const appUpdateSuccess = data => ({ type: actionTypes.APP.UPDATE_SUCCESS, data });

export const appPrepareCheckout = () => ({ type: actionTypes.APP.PREPARE_CHECKOUT });
export const appPrepareCheckoutSuccess = data => ({ type: actionTypes.APP.PREPARE_CHECKOUT_SUCCESS, data });

export const appCancelSubscription = () => ({ type: actionTypes.APP.CANCEL_SUBSCRIPTION });
export const appCancelSubscriptionSuccess = () => ({ type: actionTypes.APP.CANCEL_SUBSCRIPTION_SUCCESS });

export const appCompleteOnboarding = () => ({ type: actionTypes.APP.COMPLETE_ONBOARDING });
export const appCompleteOnboardingSuccess = () => ({ type: actionTypes.APP.COMPLETE_ONBOARDING_SUCCESS });

export const appGetContacts = () => ({ type: actionTypes.APP.GET_CONTACTS });
export const appGetContactsSuccess = data => ({ type: actionTypes.APP.GET_CONTACTS_SUCCESS, data });

export const appNewContact = data => ({ type: actionTypes.APP.NEW_CONTACT, data });
export const appNewContactSuccess = data => ({ type: actionTypes.APP.NEW_CONTACT_SUCCESS, data });

export const appEditContact = data => ({ type: actionTypes.APP.EDIT_CONTACT, data });
export const appEditContactSuccess = data => ({ type: actionTypes.APP.EDIT_CONTACT_SUCCESS, data });

export const appRemoveContact = data => ({ type: actionTypes.APP.REMOVE_CONTACT, data });
export const appRemoveContactSuccess = data => ({ type: actionTypes.APP.REMOVE_CONTACT_SUCCESS, data });

export const appNewAlbum = data => ({ type: actionTypes.APP.CREATE_ALBUM, data });
export const appNewAlbumSuccess = data => ({ type: actionTypes.APP.CREATE_ALBUM_SUCCESS, data });

export const appEditAlbum = data => ({ type: actionTypes.APP.EDIT_ALBUM, data });
export const appEditAlbumSuccess = data => ({ type: actionTypes.APP.EDIT_ALBUM_SUCCESS, data });

export const appRemoveAlbum = data => ({ type: actionTypes.APP.REMOVE_ALBUM, data });
export const appRemoveAlbumSuccess = data => ({ type: actionTypes.APP.REMOVE_ALBUM_SUCCESS, data });

export const appUploadPhotos = data => ({ type: actionTypes.APP.UPLOAD_PHOTOS, data });
export const appUploadPhotosSuccess = data => ({ type: actionTypes.APP.UPLOAD_PHOTOS_SUCCESS, data });

export const appEditPhoto = data => ({ type: actionTypes.APP.EDIT_PHOTO, data });
export const appEditPhotoSuccess = data => ({ type: actionTypes.APP.EDIT_PHOTO_SUCCESS, data });

export const appRemovePhoto = data => ({ type: actionTypes.APP.REMOVE_PHOTO, data });
export const appRemovePhotoSuccess = data => ({ type: actionTypes.APP.REMOVE_PHOTO_SUCCESS, data });

export const appGetExpenses = data => ({ type: actionTypes.APP.GET_EXPENSES, data });
export const appGetExpensesSuccess = data => ({ type: actionTypes.APP.GET_EXPENSES_SUCCESS, data });

export const appSubmitExpense = data => ({ type: actionTypes.APP.SUBMIT_EXPENSE, data });
export const appSubmitExpenseSuccess = data => ({ type: actionTypes.APP.SUBMIT_EXPENSE_SUCCESS, data });

export const appEditExpense = data => ({ type: actionTypes.APP.EDIT_EXPENSE, data });
export const appEditExpenseSuccess = data => ({ type: actionTypes.APP.EDIT_EXPENSE_SUCCESS, data });

export const appRemoveExpense = data => ({ type: actionTypes.APP.REMOVE_EXPENSE, data });
export const appRemoveExpenseSuccess = data => ({ type: actionTypes.APP.REMOVE_EXPENSE_SUCCESS, data });

export const appReimburseExpense = data => ({ type: actionTypes.APP.REIMBURSE_EXPENSE, data });
export const appReimburseExpenseSuccess = data => ({ type: actionTypes.APP.REIMBURSE_EXPENSE_SUCCESS, data });

export const appChangePassword = data => ({ type: actionTypes.APP.CHANGE_PASSWORD, data });
export const appChangePasswordSuccess = () => ({ type: actionTypes.APP.CHANGE_PASSWORD_SUCCESS });

export const appCreateEvent = data => ({ type: actionTypes.APP.CREATE_EVENT, data });
export const appCreateEventSuccess = data => ({ type: actionTypes.APP.CREATE_EVENT_SUCCESS, data });

export const appEditEvent = data => ({ type: actionTypes.APP.EDIT_EVENT, data });
export const appEditEventSuccess = data => ({ type: actionTypes.APP.EDIT_EVENT_SUCCESS, data });

export const appRemoveEvent = data => ({ type: actionTypes.APP.REMOVE_EVENT, data });
export const appRemoveEventSuccess = data => ({ type: actionTypes.APP.REMOVE_EVENT_SUCCESS, data });

export const appClearErr = () => ({ type: actionTypes.APP.CLEAR_ERR });
export const appClearMsg = () => ({ type: actionTypes.APP.CLEAR_MSG });
