import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

import Close from '@mui/icons-material/Close';

import TripCheckList from '../Interface/TripCheckList';
import KeyValue from '../Interface/KeyValue';

const TripPreviewModal = props => {
  const { trip, userType, sub, close, emit } = props;

  const [open, toggle] = useState(false);

  useEffect(() => {
    if (trip !== null) toggle(true);
  }, [trip]);

  const transitionFirst = () => {
    setTimeout(() => {
      close();
    }, 150);
    toggle(false);
  };

  return (
    <Modal open={open} onClose={transitionFirst}>
      <Slide in={open}>
        <Card>
          <CardHeader
            title={userType === 'client' ? trip?.type?.name : trip?.client?.user?.fullName}
            subheader={
              userType === 'client'
                ? dayjs(trip?.startDate).format('MMM D, YYYY')
                : trip?.type?.name + '. ' + dayjs(trip?.startDate).format('MMM D, YYYY')
            }
            action={
              <Stack alignItems="end" ml={3}>
                <IconButton onClick={transitionFirst} color="error">
                  <Close />
                </IconButton>
                <Button
                  disabled={userType === 'guide' && trip?.guide?.auth0 !== sub}
                  onClick={() => emit(trip?._id)}
                  variant="text"
                  sx={{ ml: 4 }}
                >
                  View Details
                </Button>
              </Stack>
            }
          />
          {userType === 'guide' ? (
            <CardContent>
              <Stack mb={3} alignItems="start">
                <KeyValue title="Guide" value={trip?.guide?.user?.fullName} />
              </Stack>
            </CardContent>
          ) : (
            <CardContent>
              <Stack mb={3} alignItems="start">
                <KeyValue title="Guide" value={trip?.guide?.user?.fullName} />
              </Stack>
              <TripCheckList userType={userType} trip={trip} />
            </CardContent>
          )}
        </Card>
      </Slide>
    </Modal>
  );
};

export default TripPreviewModal;
