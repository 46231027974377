export const countries = [
  {
    value: 'CA',
    displayValue: 'Canada',
  },
  {
    value: 'US',
    displayValue: 'United States',
  },
];

export const states = [
  {
    value: 'ab',
    displayValue: 'Alberta',
  },
  {
    value: 'bc',
    displayValue: 'British Columbia',
  },
  {
    value: 'mb',
    displayValue: 'Manitoba',
  },
  {
    value: 'nb',
    displayValue: 'New Brunswick',
  },
  {
    value: 'nl',
    displayValue: 'Newfoundland & Labrador',
  },
  {
    value: 'nt',
    displayValue: 'Northwest Territories',
  },
  {
    value: 'ns',
    displayValue: 'Nova Scotia',
  },
  {
    value: 'nu',
    displayValue: 'Nunavut',
  },
  {
    value: 'on',
    displayValue: 'Ontario',
  },
  {
    value: 'pe',
    displayValue: 'Prince Edward Island',
  },
  {
    value: 'qc',
    displayValue: 'Quebec',
  },
  {
    value: 'sk',
    displayValue: 'Saskatchewan',
  },
  {
    value: 'yt',
    displayValue: 'Yukon',
  },
  {
    value: 'ak',
    displayValue: 'Alaska',
  },
  {
    value: 'al',
    displayValue: 'Alabama',
  },
  {
    value: 'ar',
    displayValue: 'Arkansas',
  },
  {
    value: 'az',
    displayValue: 'Arizona',
  },
  {
    value: 'ca',
    displayValue: 'California',
  },
  {
    value: 'co',
    displayValue: 'Colorado',
  },
  {
    value: 'ct',
    displayValue: 'Connecticut',
  },
  {
    value: 'dc',
    displayValue: 'Washington DC',
  },
  {
    value: 'de',
    displayValue: 'Delaware',
  },
  {
    value: 'fl',
    displayValue: 'Florida',
  },
  {
    value: 'ga',
    displayValue: 'Georgia',
  },
  {
    value: 'hi',
    displayValue: 'Hawaii',
  },
  {
    value: 'ia',
    displayValue: 'Iowa',
  },
  {
    value: 'id',
    displayValue: 'Idaho',
  },
  {
    value: 'il',
    displayValue: 'Illinois',
  },
  {
    value: 'in',
    displayValue: 'Indiana',
  },
  {
    value: 'ks',
    displayValue: 'Kansas',
  },
  {
    value: 'ky',
    displayValue: 'Kentucky',
  },
  {
    value: 'la',
    displayValue: 'Louisiana',
  },
  {
    value: 'ma',
    displayValue: 'Massachusetts',
  },
  {
    value: 'md',
    displayValue: 'Maryland',
  },
  {
    value: 'me',
    displayValue: 'Maine',
  },
  {
    value: 'mi',
    displayValue: 'Michigan',
  },
  {
    value: 'mn',
    displayValue: 'Minnesota',
  },
  {
    value: 'mo',
    displayValue: 'Missouri',
  },
  {
    value: 'ms',
    displayValue: 'Mississippi',
  },
  {
    value: 'mt',
    displayValue: 'Montana',
  },
  {
    value: 'nc',
    displayValue: 'North Carolina',
  },
  {
    value: 'nd',
    displayValue: 'North Dakota',
  },
  {
    value: 'ne',
    displayValue: 'Nebraska',
  },
  {
    value: 'nh',
    displayValue: 'New Hampshire',
  },
  {
    value: 'nj',
    displayValue: 'New Jersey',
  },
  {
    value: 'nm',
    displayValue: 'New Mexico',
  },
  {
    value: 'nv',
    displayValue: 'Nevada',
  },
  {
    value: 'ny',
    displayValue: 'New York',
  },
  {
    value: 'oh',
    displayValue: 'Ohio',
  },
  {
    value: 'ok',
    displayValue: 'Oklahoma',
  },
  {
    value: 'or',
    displayValue: 'Oregon',
  },
  {
    value: 'pa',
    displayValue: 'Pennsylvania',
  },
  {
    value: 'ri',
    displayValue: 'Rhode Island',
  },
  {
    value: 'sc',
    displayValue: 'South Carolina',
  },
  {
    value: 'sd',
    displayValue: 'South Dakota',
  },
  {
    value: 'tn',
    displayValue: 'Tennessee',
  },
  {
    value: 'tx',
    displayValue: 'Texas',
  },
  {
    value: 'ut',
    displayValue: 'Utah',
  },
  {
    value: 'va',
    displayValue: 'Virginia',
  },
  {
    value: 'vt',
    displayValue: 'Vermont',
  },
  {
    value: 'wa',
    displayValue: 'Washington',
  },
  {
    value: 'wi',
    displayValue: 'Wisonsin',
  },
  {
    value: 'wv',
    displayValue: 'West Virginia',
  },
  {
    value: 'wy',
    displayValue: 'Wyoming',
  },
];
