import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import usePushNotifications from '../hooks/usePushNotifications';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Close from '@mui/icons-material/Close';

const PushNotificationSubscribe = props => {
  const { initiated } = props;
  const [modal, toggleModal] = useState(false);
  const [deny, setDeny] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const needPushEnabled = usePushNotifications();
  // For Testing Purposes
  // const needPushEnabled = true;

  const subscribe = async () => {
    const token = await getAccessTokenSilently();
    window.postMessage({ subscribeToPush: true, token });
    toggleModal(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!deny && needPushEnabled && !modal && initiated}
        onClose={() => setDeny(true)}
        sx={{ transform: 'translateY(60px)' }}
        TransitionComponent={props => <Slide {...props} direction="down" sx={{ zIndex: 1300 }} />}
      >
        <Alert
          severity="info"
          color="primary"
          variant="filled"
          onClose={() => setDeny(true)}
          action={
            <Stack justifyContent="start" sx={{ height: '100%' }}>
              <IconButton color="error" onClick={() => setDeny(true)}>
                <Close />
              </IconButton>
            </Stack>
          }
        >
          <AlertTitle sx={{ textTransform: 'capitalize' }}><Typography variant="h6" color="white" sx={{ transform: 'translateY(-3px)' }}>Stay Connected</Typography></AlertTitle>
          <Stack spacing={2}>
            <Typography>
              Recieve alerts as a notfication instead of an email. We want to remind you of upcoming trips.
            </Typography>
            <Button onClick={() => toggleModal(true)}>Subscribe To Push</Button>
          </Stack>
        </Alert>
      </Snackbar>
      <Modal open={modal} onClose={() => toggleModal(false)}>
        <Slide in={modal}>
          <Card sx={{ width: '95vw', maxWidth: '500px' }}>
            <CardHeader
              title="Push Notifications"
              subheader="Stay Connected"
              subheaderTypographyProps={{ color: 'secondary' }}
              action={
                <IconButton color="error" onClick={() => toggleModal(false)}>
                  <Close />
                </IconButton>
              }
            />
            <CardContent>
              <Stack>
                <Typography align="center">
                  In order to get the most out of this app we recommend allowing us to send push notifcations. Yes, this
                  means hitting allow on the ever-annoying notification request box. So when you are ready and want to
                  opt in please hit the button below and then select Allow on the notification permission request.
                </Typography>
                <Button disabled={!needPushEnabled} onClick={subscribe}>
                  Show Me The Notification!
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Slide>
      </Modal>
    </>
  );
};

export default PushNotificationSubscribe;
