import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  message: null,
  error: null,
  trips: [],
  download: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRIP.INIT:
      return { ...state, loading: true, error: null };
    case actionTypes.APP.INITIALIZE_SUCCESS:
      return { ...state, trips: action.data.trips ?? [] };
    case actionTypes.TRIP.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Booked!',
        trips: [...state.trips, action.data?.newClient ? action.data.trip : action.data],
      };
    case actionTypes.TRIP.EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Updated!',
        trips: state.trips.map(trip => (trip._id === action.data._id ? action.data : trip)),
      };
    case actionTypes.TRIP.REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Removed!',
        trips: state.trips.filter(trip => trip._id !== action.data),
      };
    case actionTypes.TRIP.RESEND_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.TRIP.PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Payment Added!',
        trips: state.trips.map(trip => (trip._id === action.data._id ? action.data : trip)),
      };
    case actionTypes.TRIP.FETCH_SUCCESS:
      return { ...state, loading: false, trips: action.data };
    case actionTypes.TRIP.TRAVEL_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Travel Added!',
        trips: state.trips.map(trip =>
          trip._id === action.data.trip
            ? { ...trip, travel: { ...trip.travel, accepted: action.data.accepted, details: action.data.details } }
            : trip
        ),
      };
    case actionTypes.TRIP.SIGN_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Trip Contract Signed!',
        trips: state.trips.map(trip =>
          trip._id !== action.data.tripId ? trip : { ...trip, signed: true, contractUrl: action.data.url }
        ),
      };
    case actionTypes.TRIP.DOWLOAD_CONTRACT_SUCCESS:
      return { ...state, loading: false, download: action.data };
    case actionTypes.TRIP.ERROR:
      return { ...state, loading: false, error: action.error };
    case actionTypes.TRIP.CLEAR_ERR:
      return { ...state, error: null };
    case actionTypes.TRIP.CLEAR_MSG:
      return { ...state, message: null };
    case actionTypes.ADMIN.EDIT_TRIP_TYPE_SUCCESS:
      return {
        ...state,
        trips: state.trips.map(trip => (trip?.type?._id === action.data._id ? { ...trip, type: action.data } : trip)),
      };
    default:
      return state;
  }
};

export default reducer;
