import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { adminClearMsg, appClearMsg, guideClearMsg, tripClearMsg, clientClearMsg } from '../store/actions';

const useDisplayMessages = () => {
  const [messages, setMessages] = useState([]);

  const adminMsg = useSelector(state => state.admin.message);
  const appMsg = useSelector(state => state.app.message);
  const clientMsg = useSelector(state => state.client.message);
  const guideMsg = useSelector(state => state.guide.message);
  const tripMsg = useSelector(state => state.trip.message);

  useEffect(() => {
    if (adminMsg !== null) {
      setMessages(prev => [
        ...prev,
        { severity: 'success', reducer: 'admin', message: adminMsg, close: adminClearMsg },
      ]);
    } else {
      setMessages(prev => prev.filter(message => message.reducer !== 'admin'));
    }
  }, [adminMsg]);

  useEffect(() => {
    if (appMsg !== null) {
      setMessages(prev => [...prev, { severity: 'success', reducer: 'app', message: appMsg, close: appClearMsg }]);
    } else {
      setMessages(prev => prev.filter(message => message.reducer !== 'app'));
    }
  }, [appMsg]);

  useEffect(() => {
    if (clientMsg !== null) {
      setMessages(prev => [
        ...prev,
        { severity: 'success', reducer: 'client', message: clientMsg, close: clientClearMsg },
      ]);
    } else {
      setMessages(prev => prev.filter(message => message.reducer !== 'client'));
    }
  }, [clientMsg]);

  useEffect(() => {
    if (guideMsg !== null) {
      setMessages(prev => [
        ...prev,
        { severity: 'success', reducer: 'guide', message: guideMsg, close: guideClearMsg },
      ]);
    } else {
      setMessages(prev => prev.filter(message => message.reducer !== 'guide'));
    }
  }, [guideMsg]);

  useEffect(() => {
    if (tripMsg !== null) {
      setMessages(prev => [...prev, { severity: 'success', reducer: 'trip', message: tripMsg, close: tripClearMsg }]);
    } else {
      setMessages(prev => prev.filter(message => message.reducer !== 'trip'));
    }
  }, [tripMsg]);

  return messages;
};

export default useDisplayMessages;
