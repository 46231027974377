import React from "react";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';

const ContactButtons = props => {
  const { email, phone } = props;

  return (
    <Stack direction="row">
      <Button startIcon={<Phone />} variant="text" color="secondary" href={`tel:${phone}`}>
        Phone
      </Button>
      <Button startIcon={<Email />} variant="text" color="tertiary" href={`mailto:${email}`}>
        Email
      </Button>
    </Stack>
  );
}

export default ContactButtons;