import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

const Auth = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const route = useNavigate();

  useEffect(() => {
    switch (pathname) {
      case '/login':
        loginWithRedirect();
        break;
      case '/logout':
        if (isAuthenticated) {
          logout({ logoutParams: { returnTo: window.location.origin + '/logout' } });
        } else {
          route('/');
        }
        break;
      default:
        return;
    }
  }, [pathname, isAuthenticated, loginWithRedirect, logout, route]);

  return <CircularProgress />;
};

export default Auth;
