import React, { forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Calendar as CalendarWidget, dayjsLocalizer } from 'react-big-calendar';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PreviewModal from './TripPreview';

import './Calendar.scss';

const localizer = dayjsLocalizer(dayjs);

const boxStyle = {
  width: 20,
  height: 20,
  borderRadius: 5,
};

const Calendar = forwardRef((props, transitionRef) => {
  const { name, trips, events, userType, sub, tripClick, eventClick, style } = props;

  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    if (trips[0]) {
      setDate(
        trips
          ?.filter(trip => dayjs().year() === dayjs(trip.startDate).year())
          ?.sort((a, b) => (a.startDate > b.startDate && a.startDate > dayjs() ? 1 : -1))[0]?.startDate
      );
    }
  }, [trips]);

  const [selected, setSelected] = useState(null);

  return (
    <Card ref={transitionRef} style={style}>
      <PreviewModal trip={selected} userType={userType} sub={sub} close={() => setSelected(null)} emit={tripClick} />
      <CardContent>
        <Stack direction={{ xs: 'column', md: 'row' }} pt={2} spacing={1}>
          <Stack direction="row">
            <Box sx={{ ...boxStyle, backgroundColor: 'tertiary.main' }} />
            <Typography variant="subtitle2">Your {name} Trips</Typography>
          </Stack>
          <Stack direction="row">
            <Box sx={{ ...boxStyle, backgroundColor: 'primary.main' }} />
            <Typography variant="subtitle2">Your Private Events</Typography>
          </Stack>
          {userType !== 'client' ? (
            <Stack direction="row">
              <Box sx={{ ...boxStyle, backgroundColor: 'secondary.main' }} />
              <Typography variant="subtitle2">Other {name} Trips</Typography>
            </Stack>
          ) : null}
        </Stack>
        <CalendarWidget
          className={`theme-${process.env.REACT_APP_THEME ?? 'hunt'}`}
          events={[
            ...(trips?.map(trip => ({
              title: userType === 'client' ? trip?.type.name : trip?.client?.user?.fullName,
              start: trip.startDate,
              end: trip.endDate,
              ref: trip._id,
              trip: true,
              guided: userType !== 'client' ? sub === trip?.guide?.auth0 : false,
              allDay: true,
            })) ?? []),
            ...(events?.map(event => ({
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              ref: event._id,
              trip: false,
              allDay: true,
            })) ?? []),
          ]}
          eventPropGetter={e => ({
            className:
              userType !== 'client'
                ? e.trip
                  ? e.guided
                    ? 'trip'
                    : 'otherTrip'
                  : 'userEvent'
                : e.trip
                ? 'trip'
                : 'userEvent',
          })}
          tooltipAccessor={e => {
            if (e.trip) {
              const trip = trips.find(trip => trip._id === e.ref);

              return userType === 'client'
                ? 'Guided by: ' + trip?.guide?.user?.fullName
                : trip?.type?.name + ' - ' + trip?.type?.location;
            } else {
              return 'Only you can see this Event';
            }
          }}
          onSelectEvent={data => {
            if (data.trip) {
              setSelected(trips.find(trip => trip._id === data.ref));
            } else {
              eventClick(data.ref);
            }
          }}
          showAllEvents
          date={date}
          onNavigate={date => setDate(date)}
          localizer={localizer}
          defaultView="month"
          views={['month', 'agenda']}
        />
      </CardContent>
    </Card>
  );
});

export default Calendar;
