import React from 'react';

import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const KeyValue = props => {
  const { title, value } = props;

  return (
    <Stack alignItems="start" spacing={0} sx={{ minWidth: 105 }}>
      <Typography variant="body2">{title}</Typography>
      <Divider flexItem color="#000" />
      {!value ? (
        <Typography sx={{ px: 1 }} color="error">
          Not Entered
        </Typography>
      ) : title === 'Phone' ? (
        <Link href={`tel:${value}`} sx={{ px: 1, alignSelf: 'end' }}>
          {value}
        </Link>
      ) : title === 'Email' ? (
        <Link href={`mailto:${value}`} sx={{ px: 1, alignSelf: 'end' }}>
          {value}
        </Link>
      ) : (
        <Typography sx={{ textTransform: title === 'Email' ? 'none' : 'capitalize', px: 1, alignSelf: 'end', whiteSpace: 'pre-line' }}>
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default KeyValue;
