import * as actionTypes from './actionTypes';

export const adminInit = () => ({ type: actionTypes.ADMIN.INIT });
export const adminError = err => ({ type: actionTypes.ADMIN.ERROR, error: err });

export const adminGetProfile = () => ({ type: actionTypes.ADMIN.GET_PROFILE });
export const adminGetProfileSuccess = data => ({ type: actionTypes.ADMIN.GET_PROFILE_SUCCESS, data });

export const adminEditProfile = data => ({ type: actionTypes.ADMIN.EDIT_PROFILE, data });
export const adminEditProfileSuccess = data => ({ type: actionTypes.ADMIN.EDIT_PROFILE_SUCCESS, data });

export const adminUploadLogo = data => ({ type: actionTypes.ADMIN.UPLOAD_LOGO, data });
export const adminUploadLogoSuccess = data => ({ type: actionTypes.ADMIN.UPLOAD_LOGO_SUCCESS, data });

export const adminGetUsers = () => ({ type: actionTypes.ADMIN.GET_ALL_USERS });
export const adminGetUsersSuccess = data => ({ type: actionTypes.ADMIN.GET_ALL_USERS_SUCCESS, data });

export const adminGetUser = data => ({ type: actionTypes.ADMIN.GET_USER, data });
export const adminGetUserSuccess = data => ({ type: actionTypes.ADMIN.GET_USER_SUCCESS, data });

export const adminCreateUser = data => ({ type: actionTypes.ADMIN.CREATE_USER, data });
export const adminCreateUserSuccess = data => ({ type: actionTypes.ADMIN.CREATE_USER_SUCCESS, data });

export const adminEditUser = data => ({ type: actionTypes.ADMIN.EDIT_USER, data });
export const adminEditUserSuccess = data => ({ type: actionTypes.ADMIN.EDIT_USER_SUCCESS, data });

export const adminRemoveUser = data => ({ type: actionTypes.ADMIN.REMOVE_USER, data });
export const adminRemoveUserSuccess = data => ({ type: actionTypes.ADMIN.REMOVE_USER_SUCCESS, data });

export const adminUserSearch = data => ({ type: actionTypes.ADMIN.SEARCH_USERS, data });
export const adminUserSearchSuccess = data => ({ type: actionTypes.ADMIN.SEARCH_USERS_SUCCESS, data });

export const adminClearSelectedUser = () => ({ type: actionTypes.ADMIN.CLEAR_SELECTED_USER });

export const adminClientWin = data => ({ type: actionTypes.ADMIN.CLIENT_WIN, data });
export const adminClientWinSuccess = data => ({ type: actionTypes.ADMIN.CLIENT_WIN_SUCCESS, data });

export const adminGetTripType = () => ({ type: actionTypes.ADMIN.GET_TRIP_TYPE });
export const adminGetTripTypeSuccess = data => ({ type: actionTypes.ADMIN.GET_TRIP_TYPE_SUCCESS, data });

export const adminCreateTripType = data => ({ type: actionTypes.ADMIN.CREATE_TRIP_TYPE, data });
export const adminCreateTripTypeSuccess = data => ({ type: actionTypes.ADMIN.CREATE_TRIP_TYPE_SUCCESS, data });

export const adminEditTripType = data => ({ type: actionTypes.ADMIN.EDIT_TRIP_TYPE, data });
export const adminEditTripTypeSuccess = data => ({ type: actionTypes.ADMIN.EDIT_TRIP_TYPE_SUCCESS, data });

export const adminRemoveTripType = data => ({ type: actionTypes.ADMIN.REMOVE_TRIP_TYPE, data });
export const adminRemoveTripTypeSuccess = data => ({ type: actionTypes.ADMIN.REMOVE_TRIP_TYPE_SUCCESS, data });

export const adminGetReferrals = () => ({ type: actionTypes.ADMIN.GET_REFERRALS });
export const adminGetReferralsSuccess = data => ({ type: actionTypes.ADMIN.GET_REFERRALS_SUCCESS, data });

export const adminCreateReferral = data => ({ type: actionTypes.ADMIN.ADD_REFERRAL, data });
export const adminCreateReferralSuccess = data => ({ type: actionTypes.ADMIN.ADD_REFERRAL_SUCCESS, data });

export const adminEditReferral = data => ({ type: actionTypes.ADMIN.EDIT_REFERRAL, data });
export const adminEditReferralSuccess = data => ({ type: actionTypes.ADMIN.EDIT_REFERRAL_SUCCESS, data });

export const adminRemoveReferral = data => ({ type: actionTypes.ADMIN.REMOVE_REFERRAL, data });
export const adminRemoveReferralSuccess = data => ({
  type: actionTypes.ADMIN.REMOVE_REFERRAL_SUCCESS,
  data,
});

export const adminGetPosts = () => ({ type: actionTypes.ADMIN.GET_POSTS });
export const adminGetPostsSuccess = data => ({ type: actionTypes.ADMIN.GET_POSTS_SUCCESS, data });

export const adminCreatePost = data => ({ type: actionTypes.ADMIN.CREATE_POST, data });
export const adminCreatePostSuccess = data => ({ type: actionTypes.ADMIN.CREATE_POST_SUCCESS, data });

export const adminEditPost = data => ({ type: actionTypes.ADMIN.EDIT_POST, data });
export const adminEditPostSuccess = data => ({ type: actionTypes.ADMIN.EDIT_POST_SUCCESS, data });

export const adminRemovePost = data => ({ type: actionTypes.ADMIN.REMOVE_POST, data });
export const adminRemovePostSuccess = data => ({ type: actionTypes.ADMIN.REMOVE_POST_SUCCESS, data });

export const adminUpdatePolicies = data => ({ type: actionTypes.ADMIN.UPDATE_POLICIES, data });
export const adminUpdatePoliciesSuccess = data => ({ type: actionTypes.ADMIN.UPDATE_POLICIES_SUCCESS, data });

export const adminGetExampleContract = () => ({ type: actionTypes.ADMIN.EXAMPLE_CONTRACT });
export const adminGetExampleContractSuccess = data => ({ type: actionTypes.ADMIN.EXAMPLE_CONTRACT_SUCCESS, data });

export const adminGetInvoices = () => ({ type: actionTypes.ADMIN.GET_INVOICES });
export const adminGetInvociesSuccess = data => ({ type: actionTypes.ADMIN.GET_INVOICES_SUCCESS, data });

export const adminClearErr = () => ({ type: actionTypes.ADMIN.CLEAR_ERR });
export const adminClearMsg = () => ({ type: actionTypes.ADMIN.CLEAR_MSG });
