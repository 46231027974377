import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { adminClearErr, appClearErr, guideClearErr, tripClearErr, clientClearErr } from '../store/actions';

const useDisplayErrors = () => {
  const [errors, setErrors] = useState([]);

  const adminErr = useSelector(state => state.admin.error);
  const appErr = useSelector(state => state.app.error);
  const clientErr = useSelector(state => state.client.error);
  const guideErr = useSelector(state => state.guide.error);
  const tripErr = useSelector(state => state.trip.error);

  useEffect(() => {
    if (adminErr !== null) {
      setErrors(prev => [...prev, { severity: 'error', reducer: 'admin', message: adminErr, close: adminClearErr }]);
    } else {
      setErrors(prev => prev.filter(error => error.reducer !== 'admin'));
    }
  }, [adminErr]);

  useEffect(() => {
    if (appErr !== null) {
      setErrors(prev => [...prev, { severity: 'error', reducer: 'app', message: appErr, close: appClearErr }]);
    } else {
      setErrors(prev => prev.filter(error => error.reducer !== 'app'));
    }
  }, [appErr]);

  useEffect(() => {
    if (clientErr !== null) {
      setErrors(prev => [...prev, { severity: 'error', reducer: 'client', message: clientErr, close: clientClearErr }]);
    } else {
      setErrors(prev => prev.filter(error => error.reducer !== 'client'));
    }
  }, [clientErr]);

  useEffect(() => {
    if (guideErr !== null) {
      setErrors(prev => [...prev, { severity: 'error', reducer: 'guide', message: guideErr, close: guideClearErr }]);
    } else {
      setErrors(prev => prev.filter(error => error.reducer !== 'guide'));
    }
  }, [guideErr]);

  useEffect(() => {
    if (tripErr !== null) {
      setErrors(prev => [...prev, { severity: 'error', reducer: 'trip', message: tripErr, close: tripClearErr }]);
    } else {
      setErrors(prev => prev.filter(error => error.reducer !== 'trip'));
    }
  }, [tripErr]);

  return errors;
};

export default useDisplayErrors;
